import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';

import { getDismissedAt, opportunityTilesComponentsMap } from './helpers';
import { OPPORTUNITY_TILES_QUERY } from './opportunityTilesQuery';
import { useAccount } from '@core/components/Auth/AccountContext';
import Carousel, {
  CarouselTile,
  OnDismiss,
} from '@core/components/Carousel/Carousel';
import Kard from '@core/components/General/Kard/Kard';
import {
  OpportunityTilesQueryVariables,
  OpportunityTilesQuery,
} from '@core/graphql/globalTypes';
import { TrackService } from '@core/services';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';
import { MLFlags } from 'flags';
import { usePaymentStatus } from '@payments/hooks/usePaymentStatus';

export const OPPORTUNITY_TILE_CAROUSEL_TEST_ID = 'opportunity-tile-carousel';

const OpportunityTileCarousel = () => {
  const { referralsMarketing } = useFlags<MLFlags>();
  const [carouselTiles, setCarouselTiles] = useState<CarouselTile[]>([]);
  const { isMobile } = useUserDevice();
  const { accountId } = useAccount();
  const { loading: paymentStatusLoading, isPastDue } = usePaymentStatus();

  const { loading, error, data } = useQuery<
    OpportunityTilesQuery,
    OpportunityTilesQueryVariables
  >(OPPORTUNITY_TILES_QUERY, {
    skip: !accountId || paymentStatusLoading,
    variables: {
      accountId,
      dismissedAt: getDismissedAt({
        referralsMarketing,
        isPastDue,
      }),
    },
  });

  const variant = data?.opportunityTiles.variant;
  const tiles = data?.opportunityTiles.tiles;

  useEffect(() => {
    if (!tiles) return;

    TrackService.trackEvent('Opportunity Tile Carousel Viewed', {
      variant,
    });

    const supportedTiles: CarouselTile[] = [];

    for (const { body, ctaText, id, title } of tiles) {
      const Component = opportunityTilesComponentsMap[id];

      if (!Component) continue;

      supportedTiles.push({
        body,
        component: Component,
        ctaText,
        id,
        title,
      });
    }

    setCarouselTiles(supportedTiles);
  }, [tiles, variant]);

  if (loading || error || carouselTiles.length === 0) return null;

  const handleDismiss: OnDismiss = (dismissedAt, options = {}) => {
    // Most tiles use the dismissedAt as the event name default,
    // only CP is known to use an eventName option to define a different value.
    // This was added to avoid changing the CP tile's events when updating the dismissedAt key.
    const eventName = options.eventName || dismissedAt;

    if (!options.skipLocalStorageUpdate) {
      TrackService.trackClick(`Dismiss ${eventName} tile`, {
        ...options.trackingProperties,
        feature: eventName,
      });

      localStorage.setItem(eventName, dayjs().toString());
    }

    setCarouselTiles((prev) => prev.filter((tile) => tile.id !== eventName));
  };

  const carouselContent = (
    <div data-testid={OPPORTUNITY_TILE_CAROUSEL_TEST_ID}>
      <Carousel
        carouselFragmentClassnames="mw6"
        data={carouselTiles}
        onDismiss={handleDismiss}
      />
    </div>
  );

  if (isMobile) {
    return <div className="mb4">{carouselContent}</div>;
  }

  return <Kard className="mb4">{carouselContent}</Kard>;
};

export default OpportunityTileCarousel;
