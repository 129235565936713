import { createContext, useContext, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Menu, PageHeader } from '../Page/PageHeader';
import Footer from '../General/Footer/Footer';
import { LoadingSpinnerPage } from '../General/LoadingSpinner';
import { useAccountId } from '../App/Routes/AccountIdContext';
import { successStorageKey } from '../MyProgressOnboarding/MyProgressOnboardingPage';
import Nav from './Nav/Nav';
import { SIDEBAR_NAVIGATION_FEATURE_ID } from './Nav/constants';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';
import { useTracking } from '@core/services/TrackService/useTracking';
import { NavContainerQuery } from '@core/graphql/globalTypes';
import { MLError } from '@core/services';
import { MLFlags } from 'flags';

export const myProgressNewFeatureNotificationStorageKey =
  'MyProgressNewFeatureNotificationViewed';

const NAV_CONTAINER_QUERY = gql`
  query NavContainer($accountId: String!) {
    account(accountId: $accountId) {
      id
      balanceInfo {
        currentBalance
      }
      statuses {
        isChargedOff
        isBankrupt
        isClosed
      }
    }
    creditCards {
      id
      name
      cardLast4
      cardArtUrl
    }
    customer {
      id
      hasPendingAccount
      contactInfo {
        firstName
      }
    }
    offers(accountId: $accountId) {
      offerId
      offerType
      data {
        state
        subType
      }
    }
    referralOffers(accountId: $accountId) {
      data {
        offerId
      }
    }
    offerEligibility(accountId: $accountId) {
      offerType
      eligible
      ineligibleReasons
    }
    rewards(accountId: $accountId) {
      accountId
      isRewardsCard
    }
    onboardingProgress(accountId: $accountId) {
      accountId
      hasMadePayment
      hasUsedCard
      isCardActivated
      isFundingAccountAdded
      isOlderThan90Days
    }
  }
`;

type NavContainerContext = {
  contentClassName: string | undefined;
  myProgressNewFeatureNotificationViewed: string | null;
  updateMyProgressNewFeatureNotificationViewed: (newValue: string) => void;
};
export const NavContainerContext = createContext<NavContainerContext>({
  contentClassName: undefined,
  myProgressNewFeatureNotificationViewed: null,
  updateMyProgressNewFeatureNotificationViewed: () => {},
});

const NavContainer = () => {
  const { myProgressNewFeatureNotification } = useFlags<MLFlags>();
  const { trackClick } = useTracking();
  const { isMobile } = useUserDevice();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const contentClassName = !isMobile ? 'ma4' : 'mb4';
  const { accountId } = useAccountId();
  const { data, loading, error } = useQuery<NavContainerQuery>(
    NAV_CONTAINER_QUERY,
    {
      errorPolicy: 'all',
      variables: {
        accountId,
      },
    },
  );
  const [
    myProgressNewFeatureNotificationViewed,
    setMyProgressNewFeatureNotificationViewed,
  ] = useState(
    localStorage.getItem(myProgressNewFeatureNotificationStorageKey) || null,
  );
  const updateMyProgressNewFeatureNotificationViewed = (newValue: string) => {
    setMyProgressNewFeatureNotificationViewed(newValue);
    localStorage.setItem(myProgressNewFeatureNotificationStorageKey, newValue);
  };

  useEffect(() => {
    // Prevent the body from being scrollable when mobile menu is open
    document.body.style.overflow = isMobileMenuOpen ? 'hidden' : 'unset';
  }, [isMobileMenuOpen]);

  if (loading)
    return (
      <LoadingSpinnerPage
        hideAuthenticatedBanners
        hasLoginFlowHeight
        showHeader
        centerContent
        withFooter
      />
    );

  // If we failed to fetch the customer's accounts we shouldn't display anything
  if (!data || (error && !data.creditCards.length)) {
    const errorMessage = 'NavContainer: Failed to fetch customer accounts';
    MLError.report({ name: errorMessage });
    throw new Error(errorMessage);
  }
  const { account, onboardingProgress } = data;
  const { isClosed, isBankrupt } = account?.statuses || {};
  const dismissedMyProgressOnboardingSuccess = Boolean(
    localStorage.getItem(successStorageKey),
  );
  const {
    hasMadePayment,
    hasUsedCard,
    isCardActivated,
    isFundingAccountAdded,
    isOlderThan90Days,
  } = onboardingProgress || {};
  const hasCompletedMyProgressOnboardingSteps = Boolean(
    hasMadePayment &&
      hasUsedCard &&
      isCardActivated &&
      isFundingAccountAdded &&
      dismissedMyProgressOnboardingSuccess,
  );
  const isIneligibleForMyProgressOnboarding = Boolean(
    isOlderThan90Days || isBankrupt || isClosed,
  );
  const newMyProgressFeatureAvailable =
    myProgressNewFeatureNotification.newestFeature !== null &&
    myProgressNewFeatureNotification.newestFeature !==
      myProgressNewFeatureNotificationViewed;
  const showMyProgressNavItemNotification =
    !hasCompletedMyProgressOnboardingSteps &&
    !isIneligibleForMyProgressOnboarding &&
    newMyProgressFeatureAvailable;

  const navMenu: Menu = {
    onOpen: () => {
      setIsMobileMenuOpen(true);
      trackClick({
        name: 'Mobile Sidebar Nav Opened',
        feature: SIDEBAR_NAVIGATION_FEATURE_ID,
      });
    },
    notificationCount: showMyProgressNavItemNotification ? 1 : undefined,
  };

  return (
    <>
      {isMobile && <PageHeader menu={navMenu} />}
      <div
        style={
          !isMobile ?
            {
              display: 'grid',
              gridTemplateColumns: '256px calc(100vw - 256px)',
            }
          : undefined
        }
      >
        <Nav
          data={data}
          isMobile={isMobile}
          isMobileOpen={isMobileMenuOpen}
          onMobileClose={() => setIsMobileMenuOpen(false)}
          showMyProgressNotification={showMyProgressNavItemNotification}
        />
        <NavContainerContext.Provider
          value={{
            contentClassName,
            myProgressNewFeatureNotificationViewed,
            updateMyProgressNewFeatureNotificationViewed,
          }}
        >
          <Outlet />
        </NavContainerContext.Provider>
      </div>
      <Footer contentClassName={!isMobile ? 'ml8 mr4' : undefined} />
    </>
  );
};

export const useNavContainerContext = () => useContext(NavContainerContext);

export default NavContainer;
