import { useEffect } from 'react';
import { Notification } from '@missionlane/compass-ui';
import PageWrapper from '../Page/PageWrapper';
import { PageHeader } from '../Page/PageHeader';
import LoginContainer from './Login/LoginContainer';
import { useTracking } from '@core/services/TrackService/useTracking';

interface Props {
  feature: string;
}

const LockedOut = ({ feature }: Props) => {
  const { trackPage, trackError } = useTracking();
  useEffect(() => {
    trackPage({ name: 'Locked Out', feature });
    trackError({
      name: 'Login Security Locked Out',
      feature,
      error: { code: 'E0000069', message: 'LOCKED_OUT', name: 'Locked Out' },
    });
  }, []);

  return (
    <PageWrapper centerContent withFooter>
      <div className="pa4">
        <PageHeader centerContent hasVerticalPaddingOnly />
      </div>
      <LoginContainer header="You've been locked out">
        <Notification level="error">
          For your security, you are prevented from trying to sign in again
          right now. Try again in 30 minutes.
        </Notification>
      </LoginContainer>
    </PageWrapper>
  );
};

export default LockedOut;
