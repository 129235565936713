import dayjs from 'dayjs';

import AddPaymentMethodTile, {
  ADD_PAYMENT_METHOD_TILE_DISMISSED_AT,
} from './OpportunityTiles/AddPaymentMethodTile';
import AutopayTile, {
  TRY_AUTOPAY_TILE_DISMISSED_AT,
} from './OpportunityTiles/AutopayTile';
import { PROACTIVE_MULTICARD_TILE_DISMISSED_AT } from '@clip/Multicard/ProactiveMulticardOffer';
import ProactiveMulticardTile from '@clip/Multicard/ProactiveMulticardTile';
import ReactiveMulticardTile, {
  REACTIVE_MULTICARD_TILE_DISMISSED_AT,
} from '@clip/Multicard/ReactiveMulticardTile';
import { CarouselTile } from '@core/components/Carousel/Carousel';
import CreditProtectionTile, {
  CREDIT_PROTECTION_TILE_DISMISSED_AT,
} from '@core/components/CreditProtection/CreditProtectionTile';
import { successStorageKey } from '@core/components/MyProgressOnboarding/MyProgressOnboardingPage';
import OnboardingOpportunityTile, {
  ONBOARDING_TILE_DISMISSED_AT,
} from '@core/components/Onboarding/OnboardingOpportunityTile';
import ReferralsTile from '@core/components/Referrals/ReferralsTile';
import { DismissedAt, OpportunityTileId } from '@core/graphql/globalTypes';

export type TileDismissedAt =
  | typeof TRY_AUTOPAY_TILE_DISMISSED_AT
  | typeof CREDIT_PROTECTION_TILE_DISMISSED_AT
  | typeof ADD_PAYMENT_METHOD_TILE_DISMISSED_AT
  | typeof ONBOARDING_TILE_DISMISSED_AT
  | typeof PROACTIVE_MULTICARD_TILE_DISMISSED_AT
  | typeof REACTIVE_MULTICARD_TILE_DISMISSED_AT;

export const opportunityTilesComponentsMap: Partial<
  Record<OpportunityTileId, CarouselTile['component']>
> = {
  [OpportunityTileId.Autopay]: AutopayTile,
  [OpportunityTileId.CreditProtection]: CreditProtectionTile,
  [OpportunityTileId.FundingAccount]: AddPaymentMethodTile,
  [OpportunityTileId.Onboarding]: OnboardingOpportunityTile,
  [OpportunityTileId.ProactiveMulticard]: ProactiveMulticardTile,
  [OpportunityTileId.ReactiveMulticard]: ReactiveMulticardTile,
  [OpportunityTileId.Referrals]: ReferralsTile,
};

export const getDismissedAt = ({
  referralsMarketing,
  isPastDue,
}: {
  referralsMarketing: string | null;
  isPastDue: boolean;
}) =>
  ({
    [OpportunityTileId.Autopay]: localStorage.getItem(
      TRY_AUTOPAY_TILE_DISMISSED_AT,
    ),
    [OpportunityTileId.CreditProtection]: localStorage.getItem(
      CREDIT_PROTECTION_TILE_DISMISSED_AT,
    ),
    [OpportunityTileId.FundingAccount]: localStorage.getItem(
      ADD_PAYMENT_METHOD_TILE_DISMISSED_AT,
    ),
    [OpportunityTileId.Onboarding]:
      localStorage.getItem(ONBOARDING_TILE_DISMISSED_AT) ||
      localStorage.getItem(successStorageKey),
    [OpportunityTileId.PushNotifications]: 'NOT SUPPORTED',
    [OpportunityTileId.ProactiveMulticard]: localStorage.getItem(
      PROACTIVE_MULTICARD_TILE_DISMISSED_AT,
    ),
    [OpportunityTileId.ReactiveMulticard]: localStorage.getItem(
      REACTIVE_MULTICARD_TILE_DISMISSED_AT,
    ),
    [OpportunityTileId.Referrals]:
      referralsMarketing === 'DISABLED' || isPastDue ?
        referralsMarketing
      : null,
  }) as DismissedAt;

export const dismissTile = (dismissedAt: TileDismissedAt) => {
  localStorage.setItem(dismissedAt, dayjs().toString());
};
