import { gql } from '@apollo/client';
import { Navigate, useNavigate } from 'react-router-dom';
import { Button, Spacer } from '@missionlane/compass-ui';
import AutoPayPaymentDisplay from '../AutoPayPaymentDisplay';
import { useAutopay } from '../AutopayContext';
import {
  ManageAutoPayQuery,
  ManageAutoPayQueryVariables,
} from '@core/graphql/globalTypes';
import { useAccountIdQuery } from '@core/utils/hooks/useAccountIdQuery';
import PageWrapper from '@core/components/Page/PageWrapper';
import { useCustomerAndAccountIdentifiers } from '@core/utils/hooks/useCustomerAndAccountIdentifiers';
import { TrackService } from '@core/services';
import { LoadingSpinnerPage } from '@core/components/General/LoadingSpinner';

export const MANAGE_AUTO_PAY_QUERY = gql`
  query ManageAutoPay($accountId: String!) {
    account(accountId: $accountId) {
      id
      cardDetails {
        last4
        issuanceType
        issuanceState
      }
      autopay {
        id
        amount
        paymentType
        dayOfMonth
        eftaUrl
        nextPaymentDate
        fundingAccount {
          id
          bankName
          numberLast4
        }
      }
    }
  }
`;

const ManageAutoPay = () => {
  const customerAndAccountIds = useCustomerAndAccountIdentifiers();
  const { setAutopayFlowType } = useAutopay();
  const navigate = useNavigate();

  const { data, loading } = useAccountIdQuery<
    ManageAutoPayQuery,
    ManageAutoPayQueryVariables
  >(MANAGE_AUTO_PAY_QUERY);

  setAutopayFlowType('MODIFY');

  if (loading) {
    return <LoadingSpinnerPage />;
  }

  const { autopay: autopays = [] } = data?.account || {};
  const autopay = autopays[0];

  if (!autopay) {
    return <Navigate to="/" />;
  }

  const handleClickModify = () => {
    TrackService.click('Modify Auto Pay', customerAndAccountIds);

    navigate('..');
  };

  const handleClickTurnOff = () => {
    TrackService.click('Cancel Auto Pay', customerAndAccountIds);

    navigate('../cancel');
  };

  return (
    <PageWrapper
      isSingleColumn
      withBackButton="../.." // go back to the payments page
      pageTitle={{ primaryText: 'Your Autopay Plan', useLast4: true }}
      trackingProperties={{
        featureName: 'Payments: Autopay',
        pageName: 'Manage Autopay',
      }}
    >
      <AutoPayPaymentDisplay
        active
        amount={autopay.amount}
        paymentType={autopay.paymentType}
        dayOfMonth={autopay.dayOfMonth}
        fundingAccount={autopay.fundingAccount}
        paymentDateLabel="Next Payment"
        nextPaymentDate={autopay.nextPaymentDate}
      />

      <div className="flex flex-row-ns flex-column mt3 ">
        <Button onPress={handleClickModify} text="Modify" variant="contained" />
        <Spacer size="m" direction="horizontal" />
        <Button
          variant="outlined"
          onPress={handleClickTurnOff}
          text="Turn Off"
        />
      </div>
    </PageWrapper>
  );
};

export default ManageAutoPay;
