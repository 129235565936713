import { useEffect } from 'react';
import useClipOfferV2 from '@clip/ClipProgress/hooks/useClipOfferV2';
import './PaymentStreak.css';
import { useTracking } from '@core/services/TrackService/useTracking';
import {
  CLIP_STREAK_FEATURE_NAME,
  ClipEventNames,
} from '@clip/shared/lib/events';

function PaymentStreak() {
  const clipOffer = useClipOfferV2();
  const { trackEvent } = useTracking();

  useEffect(() => {
    trackEvent({
      eventName: ClipEventNames.ViewKard,
      feature: CLIP_STREAK_FEATURE_NAME,
      metadata: {
        clipOffer,
      },
    });
  }, []);

  return (
    <div className="flex items-center payment-streak-container">
      <div className="flex ba bw2 br-100 flex-shrink-0 items-center justify-center payment-streak-counter-outer-container">
        <div className="flex ba bw2 br-100 flex-shrink-0 items-center justify-center payment-streak-counter-inner-container">
          <h2>{clipOffer?.currentPaymentStreak}</h2>
        </div>
      </div>
      <div className="flex flex-column">
        <h4>Payments Streak</h4>
        <p className="mt1 mb0">
          You've gone this many statements in a row without missing a payment.
        </p>
      </div>
    </div>
  );
}

export default PaymentStreak;
