import cx from 'classnames';
import { Button } from '@missionlane/compass-ui';
import { useNavigate } from 'react-router-dom';
import NotificationBubble from '../General/NotificationBubble/NotificationBubble';
import { HeaderLogo } from './HeaderLogo';

export type Menu = {
  onOpen: () => void;
  notificationCount?: number;
};

interface PageHeaderProps {
  menu?: Menu;
  centerContent?: boolean;
  hasVerticalPaddingOnly?: boolean;
}

export const PageHeader = ({
  menu,
  centerContent = false,
  hasVerticalPaddingOnly = false,
}: PageHeaderProps) => (
  <div
    className={cx('flex mb2-ns', hasVerticalPaddingOnly ? 'pb4' : 'pa4', {
      'justify-between': !!menu,
      'mw8 w-100 center': centerContent,
    })}
  >
    <HeaderLogo color="green" />
    {menu && (
      <div className="relative">
        <Button
          // @ts-ignore - TODO: Let compass accept colors here
          color="inkLight"
          variant="icon"
          icon="bars"
          accessibilityLabel="Open menu"
          onPress={menu.onOpen}
        />
        {!!menu.notificationCount && (
          <NotificationBubble
            notificationCount={menu.notificationCount}
            size={22}
            right={-4}
          />
        )}
      </div>
    )}
  </div>
);

interface PageHeaderWithSignOutProps {
  centerContent?: boolean;
}

export const PageHeaderWithSignOut = ({
  centerContent,
}: PageHeaderWithSignOutProps) => {
  const navigate = useNavigate();
  return (
    <div
      className={cx('flex pa3 pa4-ns mb2-ns justify-between items-center', {
        'mw8 w-100 center': centerContent,
      })}
    >
      <HeaderLogo color="green" />
      <Button
        size="small"
        variant="text"
        text="Sign out"
        onPress={() => navigate('/signout')}
      />
    </div>
  );
};
