import dayjs from 'dayjs';
import { gql } from '@apollo/client';
import { useAccountIdQuery } from '@core/utils/hooks/useAccountIdQuery';
import {
  PaymentHistoryModalQuery,
  PaymentHistoryModalQueryVariables,
} from '@core/graphql/globalTypes';
import PageWrapper from '@core/components/Page/PageWrapper';
import PaymentsList from '@payments/components/PaymentsOverview/PaymentsList';
import { LoadingSpinnerPage } from '@core/components/General/LoadingSpinner';
import PAYMENT_FIELDS from '@payments/graphql/PaymentFieldsFragment';
import { MLError } from '@core/services';
import PageTitle from '@core/components/Page/PageTitle/PageTitle';

export const PAYMENT_HISTORY_QUERY = gql`
  query PaymentHistoryModal(
    $accountId: String!
    $fromDate: String!
    $toDate: String!
  ) {
    account(accountId: $accountId) {
      id
      payments(fromDate: $fromDate, toDate: $toDate) {
        ...PaymentFields
      }
    }
  }
  ${PAYMENT_FIELDS}
`;

const CompletedPayments = () => {
  const { data, loading, error } = useAccountIdQuery<
    PaymentHistoryModalQuery,
    PaymentHistoryModalQueryVariables
  >(PAYMENT_HISTORY_QUERY, {
    variables: {
      // fetch 1 year of payments
      fromDate: dayjs().subtract(1, 'year').format('YYYY-MM-DD'),
      toDate: dayjs().format('YYYY-MM-DD'),
    },
  });

  if (loading) {
    return <LoadingSpinnerPage />;
  }

  if (error || !data) {
    MLError.report({
      name: 'Completed Payments Query Error',
      message: error ? error.message : 'Payment data is undefined',
    });

    throw new Error(
      `Completed Payments Query Error - ${
        error ? error.message : 'Payment data is undefined'
      }`,
    );
  }

  const payments = data.account?.payments || [];

  return (
    <PageWrapper
      trackingProperties={{
        featureName: 'Payments',
        pageName: 'Completed Payments',
      }}
    >
      <PageTitle
        title={{ primaryText: 'Completed Payments', useLast4: true }}
      />
      <PaymentsList payments={payments} paymentDetailsRoute="../" />
    </PageWrapper>
  );
};

export default CompletedPayments;
