import { B, BubbleIcon, P3 } from '@missionlane/compass-ui';
import TransactionItem from '@core/components/Transactions/TransactionItem';
import { GetPaymentsOverview, Transactions } from '@core/graphql/globalTypes';

const NoActivity = () => (
  <div className="bg-haze-lightest flex flex-column items-center pv4 ph3 mt3 ph0-ns">
    <BubbleIcon name="dollarSign" bubbleColor="inkLightest" iconColor="ink" />
    <P3 color="ink">
      <B>Nothing Yet</B>
    </P3>
    <div className="w-60-ns tc">
      <P3>
        You haven't made any payments. When you make one, you can find it here!
      </P3>
    </div>
  </div>
);

interface Props {
  payments: GetPaymentsOverview.Payments[];
  paymentDetailsRoute?: string;
}

const PaymentsList = ({ payments, paymentDetailsRoute }: Props) => {
  if (!payments.length) {
    return <NoActivity />;
  }

  return (
    <ul className="bg-white pa0 mv0">
      {payments.map((payment) => (
        <TransactionItem
          key={payment.id}
          transaction={payment}
          paymentDetailsRoute={paymentDetailsRoute}
        />
      ))}
    </ul>
  );
};

export default PaymentsList;
