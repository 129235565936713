export const CLIP_TRACKER_FEATURE_NAME = 'Clip Tracker Relaunch';
export const CLIP_STREAK_FEATURE_NAME = 'Clip Streak Relaunch';

export enum ClipEventNames {
  ViewKard = 'Offer Viewed',
  ViewEnableAutopay = 'Enable Autopay Message Viewed',
  ClickAutopaySetup = 'Enable Autopay Message Clicked',
  ClickSeeOfferDetails = 'See Offer Details Clicked',
  ClickCloseOfferDetails = 'Close Offer Details Clicked',
  ViewPaymentPendingWarning = 'Payment Pending Warning Viewed',
  ViewNeverActiveWarning = 'Never Active Warning Viewed',
  ClickMakePayment = 'Make Payment Button Clicked',
}
