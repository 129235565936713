import { B } from '@missionlane/compass-ui';
import ResponsiveTypography from '@core/components/General/ResponsiveTypography';

export const CREDIT_PROTECTION_FAQ_1_TITLE =
  'How does Mission Lane Credit Protection Work?';

const CreditProtectionFAQ_1 = () => {
  return (
    <div>
      <ol>
        <li className="b">
          <ResponsiveTypography
            type="PARAGRAPH"
            mobileLevel="P1"
            desktopLevel="P3"
          >
            <B color="ink">Benefit Initiation Timeline:</B> Typically, you can
            initiate a benefit for eligible events that occur after your
            enrollment date. However, in the case of involuntary unemployment,
            you can only initiate a benefit if the date of unemployment is 30
            days or more after your enrollment in Mission Lane Credit
            Protection.
          </ResponsiveTypography>
        </li>
        <li className="b">
          <ResponsiveTypography
            type="PARAGRAPH"
            mobileLevel="P1"
            desktopLevel="P3"
          >
            <B color="ink">Benefit Initiation Process:</B> To start a benefit,
            simply call the phone number provided in your welcome letter. A
            representative will guide you through the process and advise you on
            the next steps.
          </ResponsiveTypography>
        </li>
        <li className="b">
          <ResponsiveTypography
            type="PARAGRAPH"
            mobileLevel="P1"
            desktopLevel="P3"
          >
            <B color="ink">Benefit Processing Timeline:</B> Your benefit may be
            approved immediately. Sometimes, you'll need to provide supporting
            documents for the benefit to continue. In other cases, your benefit
            may not begin until the documents are approved, which could take up
            to 30 days. <B color="ink">Note:</B> If your benefit isn't
            immediate, you must continue making credit card payments until your
            request is approved.
          </ResponsiveTypography>
        </li>
        <li className="b">
          <ResponsiveTypography
            type="PARAGRAPH"
            mobileLevel="P1"
            desktopLevel="P3"
          >
            <B color="ink">Approval Status Communication:</B> The representative
            will inform you of your benefit status over the phone. We'll also
            follow up with an email confirming your status and, if necessary,
            requesting additional documentation.
          </ResponsiveTypography>
        </li>
        <li className="b">
          <ResponsiveTypography
            type="PARAGRAPH"
            mobileLevel="P1"
            desktopLevel="P3"
          >
            <B color="ink">Benefits:</B> If your benefit is approved, the
            monthly payment or new balance shown on your statement will be
            canceled. For Involuntary Unemployment and Disability benefits to
            continue after month 1, you will need to provide supporting
            documents.{' '}
            <B color="ink">
              Please consult your Terms and Conditions for details on specific
              benefits.
            </B>
          </ResponsiveTypography>
        </li>
      </ol>
    </div>
  );
};

export default CreditProtectionFAQ_1;
