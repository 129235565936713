import { yupResolver } from '@hookform/resolvers/yup';
import { Notification } from '@missionlane/compass-ui';
import { AuthnTransaction } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import { useEffect, useRef, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { NativeSyntheticEvent, TextInputKeyPressEventData } from 'react-native';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { useFlags } from 'launchdarkly-react-client-sdk';
import DefaultAuthError from '../DefaultAuthError';
import { OktaErrorMessage } from '../OktaErrorMessage';
import { isOktaError, OktaResponseError } from '../types';
import { usernameSchema } from '../validation';

import LoginActionButtons from './LoginActionButtons';
import LoginContainer from './LoginContainer';
import { LoginLink } from './LoginLink';
import { areCookiesEnabled } from '@core/utils/areCookiesEnabled';
import { RiskAuthServiceError } from '@core/services/RiskAuthService/errors';
import { HookFormTextField } from '@core/components/Form';
import { useTracking } from '@core/services/TrackService/useTracking';
import PageWrapper from '@core/components/Page/PageWrapper';
import { PageHeader } from '@core/components/Page/PageHeader';
import LoadingSpinner from '@core/components/General/LoadingSpinner';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';
import { useRiskAuth } from '@core/components/Auth/RiskAuthProvider';

const COOKIE_WARNING =
  'Please turn on cookies in your browser settings to sign in.';

const validationSchema = Yup.object().shape({
  username: usernameSchema,
  password: Yup.string().nullable().required('This field is required.'),
});

interface LoginFormValues {
  username: string;
  password: string;
}

interface LoginPageProps {
  onSuccess: (transaction: AuthnTransaction) => void;
  loading?: boolean;
}

const LoginPage = ({ onSuccess, loading }: LoginPageProps) => {
  const { signinDeepLinkRedirect } = useFlags();
  const { trackClick, trackEvent } = useTracking();
  const [authError, setAuthError] = useState<OktaResponseError | Error>();
  const [maskPassword, setMaskPassword] = useState(true);
  const navigate = useNavigate();
  const { oktaAuth } = useOktaAuth();
  const { error: riskAuthError, setError: setRiskAuthError } = useRiskAuth();
  const cookiesAreEnabled = useRef(areCookiesEnabled()).current;
  const { isMobile, isInRNWebView } = useUserDevice();

  const storedUsername = localStorage.getItem('username') ?? '';

  const form = useForm<LoginFormValues>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
    defaultValues: {
      username: storedUsername,
      password: '',
    },
  });

  const {
    handleSubmit,
    setValue,
    getValues, // NOTE: The values do not get updated on rerenders, use watch for that
    formState: { errors, isSubmitting, isValid, touchedFields },
  } = form;

  const fieldNames = Object.keys(getValues());
  const loginError = authError || riskAuthError;

  const clearStoredUsername = () => localStorage.setItem('username', '');

  const handleLogin = handleSubmit(async ({ username, password }) => {
    // clear prior status
    setAuthError(undefined);

    setRiskAuthError(undefined);

    trackClick({
      name: 'Login',
      feature: 'Login',
    });

    try {
      const transaction = await oktaAuth.signInWithCredentials({
        username,
        password,
      });

      if (transaction.status === 'MFA_ENROLL') {
        throw new Error('User should not not be MFA_ENROLL.');
      }

      localStorage.setItem('username', username);

      trackEvent({
        feature: 'Login',
        eventName: 'Login Success',
      });

      onSuccess(transaction);
    } catch (e: unknown) {
      if (isOktaError(e) || e instanceof Error) {
        setAuthError(e);
      } else {
        setAuthError(
          new Error('There was an unknown error while attempting to login.'),
        );
      }
    }
  });

  const handleKeyPress = (
    e: NativeSyntheticEvent<TextInputKeyPressEventData>,
  ) => {
    if (e.nativeEvent.key === 'Enter') handleLogin();
  };

  const trackingProperties = {
    pageName: 'Login',
    featureName: 'Login',
    metadata: {
      isInRNWebView,
    },
  };

  useEffect(() => {
    if (!cookiesAreEnabled)
      trackEvent({
        eventName: 'Warning Viewed',
        name: 'Cookies Disabled Warning',
        feature: 'Login',
        error: {
          name: 'Cookies Disabled Warning',
          message: COOKIE_WARNING,
          code: 'W001',
        },
      });
  }, [cookiesAreEnabled]);

  useEffect(() => {
    /**
     * user applies for multicard in CAFE
     * user is redirected from CAFE to dashboard app for login
     * dashboard app detects that user is not logged in (the user won't be logged in because its webview)
     * dashboard app deeplink redirect the user to missionlane app
     */
    if (signinDeepLinkRedirect && isInRNWebView) {
      trackEvent({
        eventName: 'Login page deeplink redirect',
        name: 'Login page deeplink redirect',
        feature: 'Login',
      });

      window.location.href = 'missionlane://';
    }
  }, [isInRNWebView, signinDeepLinkRedirect]);

  return (
    <PageWrapper
      centerContent
      withFooter
      trackingProperties={trackingProperties}
    >
      <PageHeader centerContent hasVerticalPaddingOnly={isMobile} />
      <div style={{ minHeight: !isMobile ? 543 : 507 }}>
        {loading ?
          <LoadingSpinner />
        : <LoginContainer header="Log in to your account">
            <FormProvider {...form}>
              <div className="mb4 mb4-ns">
                <HookFormTextField
                  autoFocus={true}
                  error={errors.username?.message}
                  id="username"
                  label="Email"
                  name="username"
                  transformInput={(value) => value.trim()}
                  onKeyPress={handleKeyPress}
                  onChangeText={(text) => {
                    // Assume that if the user backspaces this out manually, then the stored
                    // username is not their email
                    if (!text && storedUsername) clearStoredUsername();
                  }}
                />
                {storedUsername && (
                  <div className="mt2">
                    <LoginLink
                      onClick={() => {
                        trackClick({
                          name: 'Not your email?',
                          feature: 'Login',
                        });

                        clearStoredUsername();

                        setValue('username', '');
                      }}
                    >
                      Not your email?
                    </LoginLink>
                  </div>
                )}
              </div>
              <HookFormTextField
                error={errors.password?.message}
                id="password"
                label="Password"
                name="password"
                secureTextEntry={maskPassword}
                action={{
                  label: maskPassword ? 'Show' : 'Hide',
                  onPress: () => setMaskPassword(!maskPassword),
                }}
                onKeyPress={handleKeyPress}
              />
              {loginError && <LoginFormError authError={loginError} />}
              {!cookiesAreEnabled && (
                <Notification level="warning" variant="inline">
                  {COOKIE_WARNING}
                </Notification>
              )}
              <div className="mt3">
                <LoginActionButtons
                  submitButton={{
                    text: 'Log In',
                    onPress: handleLogin,
                    loading: isSubmitting,
                    disabled:
                      !isValid &&
                      Object.values(touchedFields).length === fieldNames.length,
                  }}
                />
              </div>
              <div className="mt4 mb2">
                <LoginLink onClick={() => navigate('../forgot-password')}>
                  Forgot your password?
                </LoginLink>
              </div>
              <div className="mb3">
                <LoginLink to="https://apply.missionlane.com/lookup" external>
                  New Member? Set up your account.
                </LoginLink>
              </div>
            </FormProvider>
          </LoginContainer>
        }
      </div>
    </PageWrapper>
  );
};

interface LoginFormErrorProps {
  authError: OktaResponseError | RiskAuthServiceError | Error;
}

const LoginFormError = ({ authError }: LoginFormErrorProps) => {
  if (isOktaError(authError)) {
    return <OktaErrorMessage error={authError} />;
  }

  // Just display a generic error for risk auth failures or other non okta auth errors
  return <DefaultAuthError error={authError} />;
};

export default LoginPage;
