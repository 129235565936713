import cx from 'classnames';
import { gql, useQuery } from '@apollo/client';
import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ProtectedRoute } from './ProtectedRoute';
import AccountDetailsPage from '@core/components/AccountDetailsPage/AccountDetailsPage';
import AccountSummaryPage from '@core/components/AccountSummaryPage/AccountSummaryPage';
import ActivationFeature from '@core/components/Activation/ActivationFeature';
import { useAccount } from '@core/components/Auth/AccountContext';
import { KatabatExternalRoute } from '@core/components/ExternalRoutes';
import LoadingSpinner from '@core/components/General/LoadingSpinner';
import { ModifyPinFeature } from '@core/components/ModifyPINPage';
import PaymentsOverview from '@payments/components/PaymentsOverview/PaymentsOverview';
import CreatePaymentPlanFlow from '@core/components/ChargedOff/PaymentPlanFlow/CreatePaymentPlanFlow';
import AddAccount from '@payments/components/BankAccount/AddBankAccount/AddAccount';
import ManageFundingAccounts from '@payments/components/BankAccount/ManageFundingAccounts/ManageFundingAccounts';
import CompletedPayments from '@payments/components/PaymentsOverview/CompletedPayments';
import { AutopayProvider } from '@payments/components/Autopay/AutopayContext';
import AutoPayForm from '@payments/components/Autopay/AutoPayForm/AutoPayForm';
import AutoPayConfirm from '@payments/components/Autopay/AutoPayConfirm';
import AutopaySuccess from '@payments/components/Autopay/AutoPaySuccess';
import CancelAutoPayConfirmation from '@payments/components/Autopay/CancelAutoPay/CancelAutoPayConfirmation';
import CancelAutoPaySuccess from '@payments/components/Autopay/CancelAutoPay/CancelAutoPaySuccess';
import ManageAutoPay from '@payments/components/Autopay/ManageAutoPay/ManageAutoPay';
import ScheduledPayments from '@payments/components/ScheduledPayments/ScheduledPayments';
import CancelPaymentConfirmation from '@payments/components/CancelPayment/CancelPaymentConfirmation';
import CancelPaymentSuccess from '@payments/components/CancelPayment/CancelPaymentSuccess';
import { CancelPaymentProvider } from '@payments/components/CancelPayment/CancelPaymentContext';
import Pay from '@payments/components/Pay/Pay';
import MakePaymentProvider from '@payments/components/MakePayment/MakePaymentContext';
import MakePayment from '@payments/components/MakePayment/MakePayment';
import PaymentConfirmation from '@payments/components/MakePayment/PaymentConfirmation';
import PaymentSuccess from '@payments/components/MakePayment/PaymentSuccess';
import PayWithCard from '@payments/components/MakePayment/PayWithCard/PayWithCard';
import FaqPage from '@core/components/ChargedOff/PaymentPlanFlow/FaqPage';
import TransactionsPage from '@core/components/Transactions/TransactionsPage';
import StatementsPage from '@core/components/Statements/StatementsPage';
import ManagePaymentPlan from '@core/components/ChargedOff/ManagePaymentPlan/ManagePaymentPlan';
import StatementsPreferencesPage from '@core/components/Statements/StatementsPreferences/StatementsPreferencesPage';
import StatementsPreferencesConfirmPage from '@core/components/Statements/StatementsPreferences/StatementsPreferencesConfirmPage';
import EditOfferTransaction from '@core/components/ChargedOff/ManagePaymentPlan/EditOfferTransaction';
import { PaymentPlanProvider } from '@core/components/ChargedOff/contexts/PaymentPlanContext';
import ConfirmPaymentPlan from '@core/components/ChargedOff/ManagePaymentPlan/ConfirmPaymentPlan';
import StatementsPreferencesSuccessPage from '@core/components/Statements/StatementsPreferences/StatementsPreferencesSuccessPage';
import {
  AccountRoutesQuery,
  AccountRoutesQueryVariables,
  SettlementOfferState,
  SettlementOfferSubtype,
} from '@core/graphql/globalTypes';
import { ActivityDetailPage } from '@core/components/Transactions/TransactionDetail/ActivityDetailPage';
import EditOfferFundingAccount from '@core/components/ChargedOff/ManagePaymentPlan/EditOfferFundingAccount';
import { useSettlementOffers } from '@core/components/ChargedOff/utils/useSettlementOffers';
import ReviewOfferTransactions from '@core/components/ChargedOff/ManagePaymentPlan/ReviewOfferTransactions';
import { EditOfferFundingAccountProvider } from '@core/components/ChargedOff/contexts/EditOfferFundingAccountContext';
import { ManageCard } from '@core/components/ManageCard/ManageCard';
import { LockCard } from '@core/components/ManageCard/LockCard/LockCard';
import DeleteOfferTransaction from '@core/components/ChargedOff/ManagePaymentPlan/DeleteOfferTransaction';
import { AutopayPrompt } from '@payments/components/MakePayment/AutopayPrompt';
import PaymentSuccessScreen from '@payments/components/MakePayment/PaymentSuccessScreen';
import AddAccountSuccess from '@payments/components/BankAccount/AddBankAccount/AddAccountSuccess';
import AddAccountSuccessAutopayPrompt from '@payments/components/BankAccount/AddBankAccount/AddAccountSuccessAutopayPrompt';
import { MLFlags } from 'flags';
import CreditProtectionPage from '@core/components/CreditProtection/CreditProtectionPage';
import CreditProtectionSuccessPage from '@core/components/CreditProtection/CreditProtectionSuccessPage';
import isNil from '@core/utils/isNil';
import MyProgressOnboardingPage, {
  successStorageKey,
} from '@core/components/MyProgressOnboarding/MyProgressOnboardingPage';
import MyProgressPage from '@core/components/MyProgress/MyProgressPage';
import CreditProtectionIneligiblePage from '@core/components/CreditProtection/CreditProtectionIneligiblePage';
import ReferralsPage from '@core/components/Referrals/ReferralsPage';
import { useLocalStorage } from '@core/utils/hooks/useLocalStorage';
import RewardsPage from '@core/components/RewardsPage/RewardsPage';
import AfterPaymentMinDuePrompt from '@payments/components/MakePayment/AfterPaymentMinDuePrompt';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';

export const ACCOUNT_ROUTES_QUERY = gql`
  query AccountRoutes($accountId: String!) {
    account(accountId: $accountId) {
      id
      isEligibleForActivation
      statuses {
        hasAppFraud
        isBankrupt
        isChargedOff
        isClosed
        isDebitEligible
        isPaymentPlanEligible
      }
      creditProtectionInfo {
        isEligible
      }
      balanceInfo {
        currentBalance
      }
    }
    onboardingProgress(accountId: $accountId) {
      accountId
      hasMadePayment
      hasUsedCard
      isCardActivated
      isFundingAccountAdded
      isOlderThan90Days
    }
    referralOffers(accountId: $accountId) {
      data {
        offerId
      }
    }
  }
`;

export const AccountRoutes = () => {
  const { accountId } = useAccount();
  const { get } = useLocalStorage();
  const successOverlayDismissedAt = get(successStorageKey);
  const { isMobile } = useUserDevice();
  const {
    showHcrExperience,
    showManageCard,
    manageFundingAccounts,
    creditProtectionMarketing,
    enableMyProgress,
    enableMyProgressOnboarding,
    enableRewardsRedemption,
    referralsMarketing,
    showEnhancedMinDuePaymentPrompt,
  } = useFlags<MLFlags>();
  const { activeOffer } = useSettlementOffers();
  const { data, loading } = useQuery<
    AccountRoutesQuery,
    AccountRoutesQueryVariables
  >(ACCOUNT_ROUTES_QUERY, {
    errorPolicy: 'all',
    variables: { accountId },
  });
  const { account, onboardingProgress } = data || {};
  const { balanceInfo, statuses } = account || {};
  const currentBalance = balanceInfo?.currentBalance;
  const {
    hasMadePayment,
    hasUsedCard,
    isCardActivated,
    isFundingAccountAdded,
    isOlderThan90Days,
  } = onboardingProgress || {};

  const hasInProgressOffer = Boolean(
    activeOffer?.data.state === SettlementOfferState.InProgress ||
      activeOffer?.data.state === SettlementOfferState.PendingCloseOut,
  );
  const hasFulfilledPifOffer =
    activeOffer &&
    !isNil(currentBalance) &&
    Boolean(
      activeOffer.data.state === SettlementOfferState.Fulfilled &&
        (activeOffer.data.subType === SettlementOfferSubtype.Pif ||
          currentBalance <= 0),
    );

  const hasReferralsOffer =
    (data?.referralOffers && data?.referralOffers.length > 0) || false;

  if (loading) {
    return (
      <div
        className={cx(isMobile && 'mb4')}
        style={{
          height: isMobile ? 507 : '100vh',
          alignContent: isMobile ? undefined : 'center',
        }}
      >
        <LoadingSpinner />
      </div>
    );
  }

  const displayPostOnboarding =
    statuses?.isBankrupt ||
    statuses?.isClosed ||
    isOlderThan90Days ||
    (isCardActivated &&
      hasUsedCard &&
      isFundingAccountAdded &&
      hasMadePayment &&
      successOverlayDismissedAt);

  const canScheduleAutopay =
    statuses?.isPaymentPlanEligible === false &&
    statuses?.isBankrupt === false &&
    !statuses?.isChargedOff;

  const canMakePayment = !!currentBalance && !statuses?.hasAppFraud;

  const getMyProgressScreen = () => {
    if (enableMyProgressOnboarding) {
      if (displayPostOnboarding) {
        return <MyProgressPage />;
      }
      return <MyProgressOnboardingPage />;
    }

    return <MyProgressPage />;
  };

  return (
    <Routes>
      <Route path="summary" element={<AccountSummaryPage />} />
      <Route
        path="my-progress"
        element={
          /** TODO: replace with FeatureRoute when introduced with side nav */
          <ProtectedRoute
            blocked={!(enableMyProgressOnboarding || enableMyProgress)}
          >
            {getMyProgressScreen()}
          </ProtectedRoute>
        }
      />
      <Route
        path="rewards"
        element={
          <ProtectedRoute blocked={!enableRewardsRedemption}>
            <RewardsPage />
          </ProtectedRoute>
        }
      />
      <Route path="transactions/*">
        <Route index element={<TransactionsPage />} />
        <Route path=":transactionId" element={<ActivityDetailPage />} />
      </Route>
      <Route path="statements/*" element={<Outlet />}>
        <Route index element={<StatementsPage />} />
        <Route path="preferences/*" element={<Outlet />}>
          <Route index element={<StatementsPreferencesPage />} />
          <Route
            path="confirm"
            element={<StatementsPreferencesConfirmPage />}
          />
          <Route
            path="success"
            element={<StatementsPreferencesSuccessPage />}
          />
        </Route>
      </Route>
      <Route
        path="payments/*"
        element={
          <ProtectedRoute
            redirectTo="../summary"
            blocked={
              !!statuses?.hasAppFraud ||
              !!statuses?.isBankrupt ||
              !!hasFulfilledPifOffer
            }
          >
            <Outlet />
          </ProtectedRoute>
        }
      >
        <Route index element={<PaymentsOverview />} />
        <Route path=":paymentId" element={<ActivityDetailPage />} />
        <Route
          path="autopay/*"
          element={
            <ProtectedRoute blocked={!canScheduleAutopay}>
              <AutopayProvider>
                <Outlet />
              </AutopayProvider>
            </ProtectedRoute>
          }
        >
          <Route index element={<AutoPayForm />} />
          <Route path="cancel" element={<CancelAutoPayConfirmation />} />
          <Route path="canceled" element={<CancelAutoPaySuccess />} />
          <Route path="manage" element={<ManageAutoPay />} />
          <Route path="confirm" element={<AutoPayConfirm />} />
          <Route path="success" element={<AutopaySuccess />} />
        </Route>
        <Route path="scheduled-payments/*">
          <Route index element={<ScheduledPayments />} />
          <Route
            path="cancel-payment/:id/*"
            element={
              <CancelPaymentProvider>
                <Outlet />
              </CancelPaymentProvider>
            }
          >
            <Route index element={<CancelPaymentConfirmation />} />
            <Route path="success" element={<CancelPaymentSuccess />} />
          </Route>
        </Route>
        <Route path="payments-completed" element={<CompletedPayments />} />
        <Route path="pay/*">
          <Route
            index
            element={
              <ProtectedRoute
                blocked={!statuses?.isDebitEligible}
                redirectTo="make-ach-payment"
              >
                <Pay />
              </ProtectedRoute>
            }
          />
          <Route
            path="get-back-on-track/*"
            element={
              <MakePaymentProvider>
                <ProtectedRoute blocked={!canMakePayment}>
                  <Outlet />
                </ProtectedRoute>
              </MakePaymentProvider>
            }
          >
            <Route index element={<MakePayment />} />
            <Route
              path="review-payment"
              element={
                <PaymentConfirmation
                  displayPostOnboarding={displayPostOnboarding}
                />
              }
            />
            <Route path="payment-submitted" element={<PaymentSuccess />} />
          </Route>
          <Route
            path="make-ach-payment/*"
            element={
              <MakePaymentProvider>
                <ProtectedRoute blocked={!canMakePayment}>
                  <Outlet />
                </ProtectedRoute>
              </MakePaymentProvider>
            }
          >
            <Route index element={<MakePayment />} />
            <Route
              path="review-payment"
              element={
                <PaymentConfirmation
                  displayPostOnboarding={displayPostOnboarding}
                />
              }
            />
            <Route path="payment-submitted" element={<PaymentSuccess />} />
            <Route
              path="payment-success-streak"
              element={<PaymentSuccessScreen />}
            />
            <Route
              path="stay-current"
              element={
                <ProtectedRoute
                  blocked={!showEnhancedMinDuePaymentPrompt}
                  redirectTo="../"
                >
                  <AfterPaymentMinDuePrompt />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="make-card-payment"
            element={
              <ProtectedRoute
                blocked={!statuses?.isDebitEligible}
                redirectTo="../make-ach-payment"
              >
                <PayWithCard />
              </ProtectedRoute>
            }
          />
          <Route path="set-up-autopay" element={<AutopayPrompt />} />
        </Route>
      </Route>

      <Route
        path="add-bank-account"
        element={
          <ProtectedRoute
            blocked={!!statuses?.hasAppFraud || !!statuses?.isBankrupt}
          >
            <AddAccount />
          </ProtectedRoute>
        }
      />
      <Route
        path="manage-bank-accounts/*"
        element={
          <ProtectedRoute
            blocked={
              (!!statuses?.hasAppFraud || !!statuses?.isBankrupt) &&
              manageFundingAccounts
            }
          >
            <Outlet />
          </ProtectedRoute>
        }
      >
        <Route index element={<ManageFundingAccounts />} />
        <Route path="success/*" element={<Outlet />}>
          <Route index element={<AddAccountSuccess />} />
          <Route path="autopay" element={<AddAccountSuccessAutopayPrompt />} />
        </Route>
      </Route>
      <Route
        path="modify-pin"
        // Handle redirect until we can deprecate external links to
        element={<Navigate to="../manage-card/modify-pin" />}
      />
      <Route
        path="manage-card/*"
        element={
          <ProtectedRoute blocked={!showManageCard}>
            <Outlet />
          </ProtectedRoute>
        }
      >
        <Route index element={<ManageCard />} />
        <Route path="activate/*" element={<ActivationFeature />} />
        <Route path="lock-card" element={<LockCard />} />
        <Route path="modify-pin" element={<ModifyPinFeature />} />
      </Route>
      <Route path="details/*" element={<Outlet />}>
        <Route index element={<AccountDetailsPage />} />
        <Route
          path="credit-protection/*"
          element={
            <ProtectedRoute
              redirectTo="../../credit-protection-ineligible"
              blocked={
                !data?.account?.creditProtectionInfo?.isEligible ||
                creditProtectionMarketing === 'DISABLED'
              }
            >
              <Outlet />
            </ProtectedRoute>
          }
        >
          <Route index element={<CreditProtectionPage />} />
          <Route path="success" element={<CreditProtectionSuccessPage />} />
        </Route>
      </Route>
      <Route path="katabat" element={<KatabatExternalRoute />} />
      <Route
        path="credit-protection-ineligible"
        element={<CreditProtectionIneligiblePage />}
      />
      <Route
        path="referrals"
        element={
          <ProtectedRoute
            blocked={referralsMarketing === 'DISABLED' || !hasReferralsOffer}
            redirectTo="../summary"
          >
            <ReferralsPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="payment-plan/*"
        element={
          <ProtectedRoute
            blocked={!showHcrExperience && !!statuses?.isPaymentPlanEligible}
          >
            <PaymentPlanProvider>
              <Outlet />
            </PaymentPlanProvider>
          </ProtectedRoute>
        }
      >
        <Route
          index
          element={
            <ProtectedRoute
              blocked={!hasInProgressOffer}
              redirectTo="../summary"
            >
              <ManagePaymentPlan />
            </ProtectedRoute>
          }
        />
        <Route
          path="edit-transaction/*"
          element={
            <ProtectedRoute
              blocked={!hasInProgressOffer}
              redirectTo="../summary"
            >
              <Outlet />
            </ProtectedRoute>
          }
        >
          <Route index element={<EditOfferTransaction />} />
          <Route path="delete" element={<DeleteOfferTransaction />} />
          <Route path="confirm" element={<ConfirmPaymentPlan />} />
        </Route>

        <Route path="create" element={<CreatePaymentPlanFlow />} />
        <Route path="faq" element={<FaqPage />} />
      </Route>
      <Route
        path="edit-offer-funding-account/*"
        element={
          <ProtectedRoute
            blocked={
              !showHcrExperience &&
              !!statuses?.isPaymentPlanEligible &&
              !hasInProgressOffer
            }
            redirectTo="../summary"
          >
            <EditOfferFundingAccountProvider>
              <Outlet />
            </EditOfferFundingAccountProvider>
          </ProtectedRoute>
        }
      >
        <Route index element={<EditOfferFundingAccount />} />
        <Route
          path="review-transactions"
          element={<ReviewOfferTransactions />}
        />
      </Route>

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
