import cx from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useContext, useEffect } from 'react';
import { gql } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import OpportunityTileCarousel from '../../OpportunityTileCarousel/OpportunityTileCarousel';
import { DashboardPropsBase } from '../types';
import OnboardingAutoPayKard from './OnboardingAutoPayKard/OnboardingAutoPayKard';
import LoadingSpinner from '@core/components/General/LoadingSpinner';
import { useAccountIdQuery } from '@core/utils/hooks/useAccountIdQuery';
import { AccountInformationContext } from '@core/components/AccountInformation/AccountInformationProvider';
import { useCustomerAndAccountIdentifiers } from '@core/utils/hooks/useCustomerAndAccountIdentifiers';
import PaymentKard from '@payments/components/PaymentKard/PaymentKard';
import RecentActivityKard from '@core/components/RecentActivityKard/RecentActivityKard';
import { CashFlowUnderwritingExperience } from '@clip/CashFlowUnderwritingExperience/kards';
import CreditScore from '@core/components/CreditScore/CreditScore';
import { usePaymentStatus } from '@payments/hooks/usePaymentStatus';
import { DefaultDashboardQuery } from '@core/graphql/globalTypes';
import { SpendBonus } from '@clip/SpendBonus';
import { IncomeUpdateBanner } from '@clip/IncomeUpdate';
import { GoodStandingTips } from '@clip/GoodStandingTips';
import { useTracking } from '@core/services/TrackService/useTracking';
import { MLFlags } from 'flags';
import ClipOffer from '@clip/ClipProgress/kards/ClipOffer/ClipOffer';
import { AccountSummaryCard } from '@core/components/AccountSummaryCard/AccountSummaryCard';
import DynamicAccountSummaryKard from '@core/components/DynamicAccountSummaryKard/DynamicAccountSummaryKard';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';
import PageWrapper from '@core/components/Page/PageWrapper';

export const DEFAULT_DASHBOARD_QUERY = gql`
  query DefaultDashboard($accountId: String!) {
    account(accountId: $accountId) {
      id
      statuses {
        isBankrupt
      }
    }
    accountDetails(accountId: $accountId) {
      id
      name
      cardLast4
      cardArtUrl
      securedCardInfo {
        currentAmount {
          value
        }
      }
      cardLockStatus {
        isLocked
      }
    }
  }
`;

const DefaultDashboard = ({ pageTitle, banners }: DashboardPropsBase) => {
  const { isDesktopLarge } = useUserDevice();
  const navigate = useNavigate();
  const { trackClick } = useTracking();
  const {
    showCreditScore,
    enableAccountSummaryCardArt,
    enableMyProgress,
    enableMyProgressOnboarding,
  } = useFlags<MLFlags>();
  const { isMobile } = useUserDevice();
  const { data, loading } = useAccountIdQuery<DefaultDashboardQuery>(
    DEFAULT_DASHBOARD_QUERY,
    {
      errorPolicy: 'all',
    },
  );

  const {
    refreshEnabled,
    refresh,
    accountInformation,
    loading: acctInfoLoading,
    rtfTreatment,
  } = useContext(AccountInformationContext);

  const customerAndAccountIds = useCustomerAndAccountIdentifiers();

  const {
    isPastDue,
    showSettledCurrentBalance,
    hasPastDueBalancePayment,
    isChargedOff,
  } = usePaymentStatus();

  useEffect(() => {
    if (refreshEnabled && !!refresh) {
      refresh({
        refetchInterval: 2000,
        refetches: 5,
      });
    }
  }, [refreshEnabled, refresh]);

  /**
   * When the card is locked, a lock icon button is displayed to
   *  navigate the user to the Card Lock feature
   */
  const onClickLock = () => {
    trackClick({
      name: 'Account Summary Card: Lock icon',
      feature: 'Account Summary',
    });
    navigate('../manage-card/lock-card');
  };

  if (loading || acctInfoLoading)
    return (
      <div
        className={cx(isMobile && 'mb4')}
        style={{
          height: isMobile ? 507 : '100vh',
          alignContent: isMobile ? undefined : 'center',
          justifyContent: 'center',
        }}
      >
        <LoadingSpinner />
      </div>
    );

  const { accountDetails } = data || {};
  const isBankrupt = data?.account?.statuses?.isBankrupt;
  const { name, cardLast4, securedCardInfo, cardLockStatus, cardArtUrl } =
    accountDetails || {};
  const { availableCredit, currentBalance } = accountInformation || {};
  const canDisplayDynamicCard = enableAccountSummaryCardArt && cardArtUrl;

  return (
    <PageWrapper banners={banners} pageTitle={pageTitle}>
      <div data-testid="DefaultDashboard">
        <IncomeUpdateBanner />
        <div className="flex">
          <div className={cx(isDesktopLarge ? 'w-50' : 'w-100')}>
            {canDisplayDynamicCard ?
              <DynamicAccountSummaryKard
                cardArtUrl={cardArtUrl}
                last4={cardLast4}
                currentBalance={currentBalance}
                availableCredit={availableCredit}
                cardName={name}
                onUnlock={onClickLock}
                isLocked={Boolean(cardLockStatus?.isLocked)}
                securityDeposit={securedCardInfo?.currentAmount?.value}
                showSettledCurrentBalance={showSettledCurrentBalance}
                isPastDue={isPastDue}
              />
            : <div className="pb3">
                <AccountSummaryCard
                  isChargedOff={isChargedOff}
                  showSettledCurrentBalance={showSettledCurrentBalance}
                  isPastDue={isPastDue}
                  headerLevel="H4"
                  availableCredit={availableCredit}
                  cardName={name}
                  cardLast4={cardLast4}
                  currentBalance={currentBalance}
                  securityDeposit={securedCardInfo?.currentAmount?.value}
                  cardAction={{
                    label: 'Account details',
                    linkTo: '../details',
                    tracking: {
                      trackingName: 'Account Summary: Account details link',
                      trackingProperties: customerAndAccountIds,
                    },
                  }}
                  rtfTreatment={rtfTreatment}
                  isLocked={Boolean(cardLockStatus?.isLocked)}
                  onUnlock={onClickLock}
                  hasPastDueBalancePayment={hasPastDueBalancePayment}
                />
              </div>
            }
            {!isDesktopLarge && (
              <>
                <div className="pb3">
                  <PaymentKard />
                </div>
                {!isBankrupt && <OpportunityTileCarousel />}
              </>
            )}
            <div className="pb3">
              <RecentActivityKard />
              <CashFlowUnderwritingExperience />
            </div>
            {!isDesktopLarge && (
              <>
                {!enableMyProgressOnboarding && !enableMyProgress && (
                  <>
                    <SpendBonus />
                    <ClipOffer />
                    <GoodStandingTips />
                  </>
                )}
                <div className="pb3">
                  <OnboardingAutoPayKard onDefaultDashboard />
                </div>
                {showCreditScore &&
                  !enableMyProgressOnboarding &&
                  !enableMyProgress && <CreditScore />}
              </>
            )}
          </div>
          {isDesktopLarge && (
            <div className="w-50 pl3">
              <div className="pb3">
                <PaymentKard />
              </div>
              <OnboardingAutoPayKard onDefaultDashboard />
              <div>
                {!isBankrupt && <OpportunityTileCarousel />}
                {!enableMyProgressOnboarding && !enableMyProgress && (
                  <>
                    <SpendBonus />
                    <ClipOffer />
                  </>
                )}
              </div>
              {!enableMyProgressOnboarding && !enableMyProgress && (
                <div>
                  <GoodStandingTips />
                </div>
              )}
              {showCreditScore &&
                !enableMyProgressOnboarding &&
                !enableMyProgress && <CreditScore />}
            </div>
          )}
        </div>
      </div>
    </PageWrapper>
  );
};

export default DefaultDashboard;
