import {
  ColorNames,
  Colors,
  Spacer,
  Icon,
  B,
  Spacing,
} from '@missionlane/compass-ui';
import { IconNames } from '@missionlane/compass-ui/lib/typescript/components/Icon/Icon';
import {
  NavLink as RouterNavLink,
  NavLinkProps as RouterNavLinkProps,
} from 'react-router-dom';
import cx from 'classnames';
import { useState } from 'react';
import ResponsiveTypography from '../../General/ResponsiveTypography';
import NotificationBubble from '../../General/NotificationBubble/NotificationBubble';
import {
  SIDEBAR_NAVIGATION_FEATURE_ID,
  SIDEBAR_NAVIGATION_EVENT_NAME_PREFIX,
} from './constants';
import { useTracking } from '@core/services/TrackService/useTracking';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';

interface SharedProps {
  text: string;
  /** Prefixed internally as the following -> "Sidebar Nav: {trackingName}"*/
  trackingName: string;
  color?: ColorNames;
  icon?: IconNames;
  notificationCount?: number;
}
interface NavLinkProps
  extends Omit<RouterNavLinkProps, 'children' | 'style' | 'color'>,
    SharedProps {
  onClick?: () => void;
}

export const NavLink = ({
  to,
  text,
  trackingName,
  color,
  icon,
  onClick,
  notificationCount,
  ...otherProps
}: NavLinkProps) => {
  const { isMobile } = useUserDevice();

  const iconLinkProps = {
    text,
    trackingName,
    color,
    icon,
    notificationCount,
  };

  return (
    <RouterNavLink
      to={to}
      style={({ isActive }) => ({
        display: 'block',
        textDecorationLine: 'none',
        backgroundColor: !isMobile && isActive ? Colors.pine : '',
      })}
      {...otherProps}
    >
      <IconLink {...iconLinkProps} onClick={() => onClick?.()} />
    </RouterNavLink>
  );
};

interface IconLinkProps extends SharedProps {
  onClick: () => void;
}

export const IconLink = ({
  onClick,
  text,
  trackingName,
  color,
  icon,
  notificationCount = 0,
}: IconLinkProps) => {
  const { isMobile } = useUserDevice();
  const { trackClick } = useTracking();
  const iconAndTextColor = color || (isMobile ? 'ink' : 'white');
  const [textLength, setTextLength] = useState(0);
  const paddingHorizontal = isMobile ? Spacing.m : Spacing.xm;
  const showNotification = notificationCount > 0;

  return (
    <div className={cx({ relative: showNotification })}>
      <div
        className={cx(
          'flex pv3 pointer items-center',
          isMobile ? 'ph3' : 'ph4',
        )}
        onClick={() => {
          onClick();
          trackClick({
            name: SIDEBAR_NAVIGATION_EVENT_NAME_PREFIX + trackingName,
            feature: SIDEBAR_NAVIGATION_FEATURE_ID,
          });
        }}
      >
        {icon && (
          <>
            <Icon name={icon} color={iconAndTextColor} size="s" />
            <Spacer direction="horizontal" size="s" />
          </>
        )}
        <ResponsiveTypography
          className={cx('flex', { mt1: !!icon })}
          type="PARAGRAPH"
          mobileLevel="P1"
          desktopLevel="P3"
          onLayout={(e) => setTextLength(e.nativeEvent.layout.width)}
          color={iconAndTextColor}
          style={{
            margin: 0,
            lineHeight: icon ? 0 : undefined,
          }}
        >
          {text}
        </ResponsiveTypography>
      </div>
      {showNotification && (
        <NotificationBubble
          notificationCount={notificationCount}
          size={isMobile ? 16 : 22}
          color={isMobile ? 'red' : 'white'}
          textColor={isMobile ? 'white' : 'ink'}
          top={isMobile ? 8 : 6}
          // Offset by horizontal padding, icon width, spacer, text length and additional 2px
          left={paddingHorizontal + Spacing.m + Spacing.s + textLength + 2}
        />
      )}
    </div>
  );
};
