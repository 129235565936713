import { gql } from '@apollo/client';

const PAYMENT_FIELDS = gql`
  fragment PaymentFields on Payment {
    amount
    date
    description
    fundingAccount {
      id
      bankName
      numberLast4
    }
    id
    reference
    state
    transactionMethod
    type
  }
`;

export default PAYMENT_FIELDS;
