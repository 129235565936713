import { useEffect, useState } from 'react';
import { AuthnTransaction } from '@okta/okta-auth-js';
import { useLocation, useNavigate } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { isOktaError } from '../types';
import ForgotPassword from './ForgotPassword';
import RecoveryChallenge from './RecoveryChallenge';
import ResetPassword from './ResetPassword';
import ResetPasswordSuccess from './ResetPasswordSuccess';
import { LoadingPage } from '@core/components/General/Loading';
import PageWrapper from '@core/components/Page/PageWrapper';
import { MLError } from '@core/services';
import { PageHeader } from '@core/components/Page/PageHeader';

const ForgotPasswordFlow = () => {
  const { oktaAuth } = useOktaAuth();
  const [authTransaction, setAuthTransaction] = useState<AuthnTransaction>();
  const { search } = useLocation();
  const [tokenError, setTokenError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleUpdateAuthTransaction = (transaction: AuthnTransaction) => {
    setAuthTransaction(transaction);
  };

  const onCancel = () => {
    setAuthTransaction(undefined);
    navigate('/signin');
  };

  const navigate = useNavigate();

  useEffect(() => {
    // an agent triggers a reset password email with url /forgot-password?recoveryToken={generatedToken} to a customer
    const skipRecoveryChallengeOtpFromEmailUrl = async () => {
      try {
        const recoveryToken = new URLSearchParams(search).get('recoveryToken');
        if (!recoveryToken) return;
        setLoading(true);
        const transaction = await oktaAuth.verifyRecoveryToken({
          recoveryToken,
        });
        handleUpdateAuthTransaction(transaction);
      } catch (error) {
        if (isOktaError(error)) {
          MLError.report({
            name: `${error.errorCode}-OktaAuthError`,
            message: error.message ?? '',
          });
          setTokenError(true);
        } else if (error instanceof Error) {
          MLError.report({
            name: error.name,
            error,
          });
        }
      } finally {
        setLoading(false);
      }
    };

    skipRecoveryChallengeOtpFromEmailUrl();
  }, [search]);

  if (loading) return <LoadingPage />;

  switch (authTransaction?.status) {
    case 'RECOVERY_CHALLENGE':
      return (
        <RecoveryChallenge
          authTransaction={authTransaction}
          onCancel={onCancel}
          onSuccess={handleUpdateAuthTransaction}
        />
      );
    case 'PASSWORD_RESET':
      return (
        <ResetPassword
          authTransaction={authTransaction}
          onCancel={onCancel}
          onSuccess={handleUpdateAuthTransaction}
        />
      );
    case 'MFA_REQUIRED':
    case 'SUCCESS':
      return <ResetPasswordSuccess />;
    default:
      return (
        <ForgotPassword
          onSuccess={handleUpdateAuthTransaction}
          tokenError={tokenError}
          onCancel={onCancel}
        />
      );
  }
};

const ForgotPasswordFlowWithWrapper = () => (
  <PageWrapper centerContent withFooter>
    <PageHeader centerContent />
    <ForgotPasswordFlow />
  </PageWrapper>
);

export default ForgotPasswordFlowWithWrapper;
