import { B, ColorNames, Colors } from '@missionlane/compass-ui';
import { CSSProperties } from 'react';
import ResponsiveTypography from '@core/components/General/ResponsiveTypography';

interface NotificationBubbleProps {
  notificationCount: number;
  size?: string | number;
  color?: ColorNames;
  textColor?: ColorNames;
  left?: number;
  top?: number;
  right?: number;
}

const NotificationBubble = ({
  notificationCount,
  size = 16,
  color = 'red',
  textColor = 'white',
  left,
  top = 0,
  right = 0,
}: NotificationBubbleProps) => {
  const bubbleSize = typeof size === 'number' ? `${size}px` : size;
  const bubbleColor = Colors[color];
  return (
    <span
      style={{
        ...baseStyles,
        left,
        top,
        right,
        backgroundColor: bubbleColor,
        width: bubbleSize,
        height: bubbleSize,
      }}
    >
      <ResponsiveTypography
        type="PARAGRAPH"
        mobileLevel="P2"
        desktopLevel="P4"
        color={textColor}
      >
        <B>{notificationCount}</B>
      </ResponsiveTypography>
    </span>
  );
};

const baseStyles: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  borderRadius: '50%',
};

export default NotificationBubble;
