import { useMediaQuery } from 'react-responsive';
import { useEffect, useState } from 'react';

const MOBILE_DEVICE_MAX_WIDTH_PX = 833;
const DESKTOP_LARGE_MIN_WIDTH_PX = 1200;
const TACHYONS_NOT_SMALL_MIN_WIDTH_EM = 30;

/**
 * Detects if the current environment is a React Native WebView
 * Checks multiple potential markers used by different React Native WebView implementations
 */
const detectReactNativeWebView = (): boolean => {
  if (typeof window === 'undefined') return false;

  const hasReactNativeWebView = !!window.ReactNativeWebView;
  const hasReactWebView = !!window.__REACT_WEB_VIEW__;
  const hasReactNativeWindow = !!window.ReactNativeWindow;

  return hasReactNativeWebView || hasReactWebView || hasReactNativeWindow;
};

export const useUserDevice = () => {
  const [isInRNWebView, setIsInRNWebView] = useState<boolean>(false);

  const isMobile = useMediaQuery({
    query: `(max-width: ${MOBILE_DEVICE_MAX_WIDTH_PX}px)`,
  });
  const isDesktopLarge = useMediaQuery({
    query: `(min-width: ${DESKTOP_LARGE_MIN_WIDTH_PX}px)`,
  });

  const isTachyonsNotSmall = useMediaQuery({
    query: `(min-width: ${TACHYONS_NOT_SMALL_MIN_WIDTH_EM}em)`,
  });

  useEffect(() => {
    setIsInRNWebView(detectReactNativeWebView());

    // in case WebView injects properties later, check again after window has fully loaded
    const handleLoad = () => {
      setIsInRNWebView(detectReactNativeWebView());
    };

    window.addEventListener('load', handleLoad);

    // If window is already loaded, check immediately
    if (document.readyState === 'complete') {
      handleLoad();
    }

    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, []);

  return { isMobile, isDesktopLarge, isTachyonsNotSmall, isInRNWebView };
};
