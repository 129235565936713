import dayjs from 'dayjs';
import { gql, useQuery } from '@apollo/client';
import { B, H4, Notification, P4 } from '@missionlane/compass-ui';
import { useEffect } from 'react';
import { FEATURE_NAME } from '../MyProgressOnboarding/const';
import ProgressBar from './ProgressBar/ProgressBar';
import Kard from '@core/components/General/Kard/Kard';
import LoadingSpinner from '@core/components/General/LoadingSpinner';
import LogoTransUnion from '@core/assets/logoTransunion.png';
import { CreditScoreQuery } from '@core/graphql/globalTypes';
import { MLError } from '@core/services';
import { useTracking } from '@core/services/TrackService/useTracking';

interface CreditScoreProps {
  className?: string;
  classNameBody?: string;
  isShadowHidden?: boolean;
}

export const CREDIT_SCORE_QUERY = gql`
  query CreditScore {
    customer {
      id
      creditInfo {
        vantageScore
        fetchedAtDateTime
      }
    }
  }
`;

const headerPrimaryText = 'Credit Score';
const errorName = 'Credit Score Kard Error';

const CreditScore = ({
  className,
  classNameBody,
  isShadowHidden,
}: CreditScoreProps) => {
  const { data, loading } = useQuery<CreditScoreQuery>(CREDIT_SCORE_QUERY);
  const { vantageScore, fetchedAtDateTime } = data?.customer?.creditInfo || {};
  const showError = !vantageScore || !fetchedAtDateTime;
  const { trackEvent } = useTracking();

  useEffect(() => {
    if (showError) {
      MLError.report({
        error: {
          name: errorName,
          message: 'No vantageScore or fetchedAtDateTime available',
        },
        name: 'Credit Score Error',
      });
      trackEvent({
        eventName: 'Error Notification Viewed',
        name: `${FEATURE_NAME.MY_PROGRESS_ONBOARDING}: ${errorName}`,
        feature: FEATURE_NAME.MY_PROGRESS_ONBOARDING,
      });
    }
  }, [showError]);

  if (loading) {
    return (
      <Kard
        className="mb3"
        header={{ textPrimary: headerPrimaryText, level: 'H4' }}
      >
        <LoadingSpinner />
      </Kard>
    );
  }

  if (showError) {
    return (
      <Kard
        className="mb3"
        header={{ textPrimary: headerPrimaryText, level: 'H4' }}
      >
        <Notification level="warning" variant="inline">
          We're having trouble loading some of your info right now.
        </Notification>
      </Kard>
    );
  }

  const formattedDate = dayjs(fetchedAtDateTime).format('MM/DD/YY');

  return (
    <Kard
      testID="Credit-Score"
      disabled={isShadowHidden}
      className={className}
      classNameBody={classNameBody}
    >
      <div className="flex justify-between items-start mb2">
        <H4>
          {vantageScore} {headerPrimaryText}
        </H4>
        <img
          // negative margin to vertically center image with text
          className="nt2 nt1-l"
          src={LogoTransUnion}
          alt="TransUnion Logo"
        />
      </div>
      <ProgressBar
        className="mv3"
        color="yellow"
        backgroundColor="inkWashed"
        progress={vantageScore / 850}
      />
      <P4>
        <B color="ink">Updated {formattedDate}.&nbsp;</B>
        Calculated by TransUnion using the VantageScore 3.0 model, which ranges
        from 300 to 850.
      </P4>
    </Kard>
  );
};

export default CreditScore;
