import dayjs from 'dayjs';
import { ReferredCustomer } from '@core/graphql/globalTypes';

type ReferredCustomerDataByYear = {
  [year: string]: {
    referralsApproved: number;
    totalEarned: number;
  };
};

export const buildReferredCustomerData = (
  referredCustomers: ReferredCustomer[],
): ReferredCustomerDataByYear => {
  const referredCustomerData =
    referredCustomers.reduce<ReferredCustomerDataByYear>(
      (acc, referredCustomer) => {
        const year = dayjs(referredCustomer.referredDateTime).format('YYYY');
        if (!acc[year]) {
          acc[year] = {
            referralsApproved: 0,
            totalEarned: 0,
          };
        }
        acc[year].referralsApproved += 1;
        acc[year].totalEarned += referredCustomer.amountStatementCredit;
        return acc;
      },
      {},
    );

  if (Object.keys(referredCustomerData).length > 0) {
    return referredCustomerData;
  } else {
    return {
      //Default Data if not referred customers yet
      [dayjs().format('YYYY')]: {
        referralsApproved: 0,
        totalEarned: 0,
      },
    };
  }
};
