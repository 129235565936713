import { LoadingIndicator } from '@missionlane/compass-ui';
import { PageHeader } from '../Page/PageHeader';
import Kard, { KardProps } from './Kard/Kard';
import PageWrapper, {
  PageWrapperProps,
} from '@core/components/Page/PageWrapper';
import Lockup from '@core/components/General/Lockup';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';

const LoadingSpinner = () => (
  <Lockup id="loading">
    <span data-testid="loading">
      <LoadingIndicator size="large" />
    </span>
  </Lockup>
);

interface Props extends Omit<PageWrapperProps, 'children'> {
  showHeader?: boolean;
  hasLoginFlowHeight?: boolean;
  hideAuthenticatedBanners?: boolean;
}

export const LoadingSpinnerPage = ({
  showHeader,
  centerContent,
  hasLoginFlowHeight,
  hideAuthenticatedBanners,
  ...otherProps
}: Props) => {
  const { isMobile } = useUserDevice();
  return (
    <>
      <PageWrapper
        {...otherProps}
        hideAuthenticatedBanners={hideAuthenticatedBanners}
        centerContent={centerContent}
      >
        {showHeader && (
          <PageHeader
            hasVerticalPaddingOnly={isMobile}
            centerContent={centerContent}
          />
        )}
        <div
          style={
            hasLoginFlowHeight ?
              {
                height: !isMobile ? 543 : 507,
              }
            : undefined
          }
        >
          <LoadingSpinner />
        </div>
      </PageWrapper>
    </>
  );
};

export const LoadingSpinnerKard = (props?: Omit<KardProps, 'children'>) => (
  <Kard {...props}>
    <LoadingSpinner />
  </Kard>
);

export default LoadingSpinner;
