import cx from 'classnames';
import { Button, LoadingIndicator } from '@missionlane/compass-ui';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useState } from 'react';
import ResponsiveTypography from '../General/ResponsiveTypography';
import PageWrapper from '../Page/PageWrapper';
import GenericFallbackFull from '../GenericFallbacks/GenericFallbackFull';
import ReferralsData from './ReferralsData';
import ReferralsInstructions from './ReferralsInstructions';
import './Referrals.css';
import ReferralsTermsModal from './ReferralsTermsModal';
import { REFERRALS_QUERY } from './ReferralsQuery';
import ReferralHeader from './ReferralHeader';
import { buildReferredCustomerData } from './helpers';
import { useAccountIdQuery } from '@core/utils/hooks/useAccountIdQuery';
import {
  ReferralOffersQuery,
  ReferralOffersQueryVariables,
} from '@core/graphql/globalTypes';
import { MLFlags } from 'flags';
import referralsHeader from '@core/assets/referralsHeader.svg';
import { useTracking } from '@core/services/TrackService/useTracking';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';
import { usePaymentStatus } from '@payments/hooks/usePaymentStatus';

const FEATURE_NAME = 'Referrals Page';

const ReferralsPage = () => {
  const { isDesktopLarge, isMobile } = useUserDevice();

  const { trackEvent, trackClick } = useTracking();
  const [showCopiedMessage, setShowCopiedMessage] = useState(false);
  const [isTermsModalOpen, setisTermsModalOpen] = useState(false);
  const referralsBaseUrl = process.env.REACT_APP_REFERRALS_URL;
  const { loading: paymentStatusLoading, isPastDue } = usePaymentStatus();

  const { data, loading, error } = useAccountIdQuery<
    ReferralOffersQuery,
    ReferralOffersQueryVariables
  >(REFERRALS_QUERY);

  if (loading || paymentStatusLoading) {
    return <LoadingIndicator />;
  }

  if (
    !data?.referralOffers ||
    data.referralOffers.length === 0 ||
    error ||
    !referralsBaseUrl
  ) {
    return <GenericFallbackFull />;
  }

  const hasActiveOffers =
    !isPastDue &&
    Boolean(
      data.referralOffers.find((referral) => referral.data.state === 'ACTIVE'),
    );

  const {
    data: {
      offerId,
      statementCreditPerReferral,
      maximumStatementCredit,
      invitationCode,
      isIncentiveOffer,
      offerVariant,
      referredCustomers,
    },
  } = data.referralOffers[0]; //Only one referral offer per account

  const referredCustomerdata = buildReferredCustomerData(
    referredCustomers || [],
  );

  const handleTermsModalClose = () => {
    trackEvent({
      eventName: 'Referrals Terms Modal Closed',
      feature: FEATURE_NAME,
      metadata: { offerVariant },
    });
    setisTermsModalOpen(false);
  };

  const handleTermsModalOpen = () => {
    trackEvent({
      eventName: 'Referrals Terms Modal Opened',
      feature: FEATURE_NAME,
      metadata: { offerVariant },
    });
    setisTermsModalOpen(true);
  };

  const handleCopyLink = (referralLink: string) => {
    trackClick({
      feature: FEATURE_NAME,
      name: 'Referral Link Copied',
      metadata: { offerVariant },
    });
    navigator.clipboard.writeText(referralLink);

    // Show "Link Copied" message
    setShowCopiedMessage(true);

    // Hide the message after 2 seconds
    setTimeout(() => {
      setShowCopiedMessage(false);
    }, 2000);
  };

  const referralLink = `${referralsBaseUrl}?invite-code=${invitationCode}&utm_source=DASH&utm_medium=referral&utm_pt_id=${offerId}&utm_pt_name=${offerVariant}`;

  return (
    <PageWrapper
      pageTitle={{ primaryText: 'Refer and Earn' }}
      trackingProperties={{
        pageName: FEATURE_NAME,
        metadata: { offerVariant },
      }}
    >
      <div className={cx(!isMobile && isDesktopLarge && 'w7')}>
        <img src={referralsHeader} className="w-100" />
        <ReferralHeader
          onClickLink={handleTermsModalOpen}
          statementCreditPerReferral={statementCreditPerReferral}
          maximumStatementCredit={maximumStatementCredit}
          isIncentiveOffer={isIncentiveOffer}
        />
        {hasActiveOffers && (
          <div className="ba flex flex-row justify-between items-center pa2 ba b--blue bw1 br2 relative">
            {showCopiedMessage && (
              <div className="copied-popup">
                <ResponsiveTypography
                  type="PARAGRAPH"
                  mobileLevel="P1"
                  desktopLevel="P3"
                  color="white"
                  className="flex items-center justify-center bg-pine br2"
                >
                  Link Copied!
                </ResponsiveTypography>
              </div>
            )}
            <ResponsiveTypography
              type="PARAGRAPH"
              mobileLevel="P1"
              desktopLevel="P3"
              color="ink"
              className={cx(isDesktopLarge ? 'w-70' : 'w-100')}
            >
              <div
                className="link-container"
                onClick={() => handleCopyLink(referralLink)}
              >
                {referralLink}
              </div>
            </ResponsiveTypography>
            {isDesktopLarge && (
              <div className="flex">
                <Button
                  variant="contained"
                  text="Copy link"
                  size="standard"
                  onPress={() => handleCopyLink(referralLink)}
                />
              </div>
            )}
          </div>
        )}
        {!isDesktopLarge && (
          <div className="pt3 items-center justify-center">
            <Button
              variant="contained"
              text="Copy link"
              size="standard"
              onPress={() => handleCopyLink(referralLink)}
            />
          </div>
        )}
        {Object.keys(referredCustomerdata)
          .sort((a: string, b: string) => Number(b) - Number(a))
          .map((key) => {
            return (
              <ReferralsData
                key={key}
                referralsApproved={referredCustomerdata[key].referralsApproved}
                totalEarned={referredCustomerdata[key].totalEarned}
                maximumStatementCredit={maximumStatementCredit}
                isIncentiveOffer={isIncentiveOffer}
                referralYear={
                  Object.keys(referredCustomerdata).length === 1 ?
                    undefined
                  : key
                }
              />
            );
          })}
        {hasActiveOffers && (
          <ReferralsInstructions
            isIncentiveOffer={isIncentiveOffer}
            statementCreditPerReferral={statementCreditPerReferral}
            onClickTerms={handleTermsModalOpen}
          />
        )}
      </div>
      {isIncentiveOffer && (
        <ReferralsTermsModal
          isOpen={isTermsModalOpen}
          maximumStatementCredit={maximumStatementCredit / 100}
          onClose={handleTermsModalClose}
        />
      )}
    </PageWrapper>
  );
};

export default ReferralsPage;
