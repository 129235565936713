import { useEffect, useState } from 'react';
import { FullStory } from '@fullstory/browser';
import cx from 'classnames';
import { Helmet } from 'react-helmet';

import { useNavContainerContext } from '../Navigation/NavContainer';
import BackLink from '../General/BackLink';
import PageTitle, { PageTitleProps } from './PageTitle/PageTitle';
import BannerContainer from '@core/components/Banners/BannerContainer';
import Footer from '@core/components/General/Footer/Footer';
import { useTracking } from '@core/services/TrackService/useTracking';
import { ErrorBoundary } from '@core/services/Error/Error';
import { TrackServiceTestProperties } from '@core/services/TrackService/TrackService';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';

export interface PageWrapperProps {
  children: React.ReactNode;
  pageTitle?: PageTitleProps['title'];
  usePageTitleInMeta?: boolean;
  centerContent?: boolean;
  isSingleColumn?: boolean;
  trackingProperties?: {
    pageName: string;
    featureName?: string;
    testProperties?: TrackServiceTestProperties;
    metadata?: Record<string, unknown>;
  };
  banners?: React.ComponentType[];
  hideAuthenticatedBanners?: boolean;
  contentClassName?: string;
  withFooter?: boolean;
  /**
   * If true, the back button will be displayed. The default behavior is to go
   * back in the history. If a string is provided, it will be used as the destination
   * for the back button. This can be a URL or a relative path.
   */
  withBackButton?: true | string;
}

const PageWrapper = ({
  children,
  pageTitle,
  usePageTitleInMeta = false,
  centerContent,
  isSingleColumn = false,
  trackingProperties,
  banners,
  hideAuthenticatedBanners = false,
  contentClassName,
  // False by default because most pages are in the nav container and get the footer that way
  withFooter = false,
  withBackButton,
}: PageWrapperProps) => {
  const { isMobile, isDesktopLarge } = useUserDevice();

  const { contentClassName: navContainerContentClassName } =
    useNavContainerContext();

  const { trackPage } = useTracking();
  const [showErrorTitle, setShowErrorTitle] = useState(false);
  const pageMetaTitle = usePageTitleInMeta ? getPageMetaTitle(pageTitle) : null;

  const displayPageTitle =
    showErrorTitle ? "It's not you. It's us." : pageTitle;

  useEffect(() => {
    if (trackingProperties) {
      trackPage({
        name: trackingProperties.pageName,
        feature: trackingProperties.featureName,
        metadata: trackingProperties.metadata,
      });
    }
  }, [trackingProperties?.pageName]);

  useEffect(() => {
    // https://developer.fullstory.com/browser/v1/set-page-properties/
    if (pageTitle)
      FullStory('setProperties', { type: 'page', properties: { pageTitle } });
  }, [pageTitle]);

  return (
    <>
      <Helmet>
        <title>Mission Lane {pageMetaTitle ? ` | ${pageMetaTitle}` : ''}</title>
        {/* TODO: Add dynamic meta description */}
      </Helmet>
      <div className="flex flex-column min-vh-100">
        <BannerContainer
          banners={banners}
          hideAuthenticatedBanners={hideAuthenticatedBanners}
        />
        <div
          id="PageWrapper"
          className={cx(
            'flex-auto pa4',
            centerContent && 'w-100 center',
            // If centered with full width we dont want to enforce mobile max width
            !centerContent && isMobile && 'mw6 w-100 center',
            !isMobile && 'mw8',
            !isMobile && (isSingleColumn || !isDesktopLarge) && 'w6',
            navContainerContentClassName,
            contentClassName,
          )}
        >
          {withBackButton && (
            <>
              <BackLink
                destination={
                  typeof withBackButton === 'string' ? withBackButton : (
                    undefined // default behavior is to go back in history
                  )
                }
              />
              <br />
            </>
          )}
          {displayPageTitle && <PageTitle title={displayPageTitle} />}
          <ErrorBoundary
            onError={() => {
              setShowErrorTitle(true);
            }}
            onUnmount={() => setShowErrorTitle(false)}
          >
            {children}
          </ErrorBoundary>
        </div>
        {withFooter && (
          <Footer contentClassName={centerContent ? 'mw8 center' : undefined} />
        )}
      </div>
    </>
  );
};

const getPageMetaTitle = (pageTitle?: PageTitleProps['title']) => {
  if (!pageTitle) return null;

  if (typeof pageTitle === 'string') {
    return pageTitle;
  }

  return pageTitle.primaryText;
};

export default PageWrapper;
