import cx from 'classnames';
import { Button, Notification } from '@missionlane/compass-ui';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { usePaymentPlanContext } from '../contexts/PaymentPlanContext';
import { SETTLEMENTS_FEATURE_NAME } from '../utils/events';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';
import PageWrapper from '@core/components/Page/PageWrapper';
import ResponsiveTypography from '@core/components/General/ResponsiveTypography';

const DeleteOfferTransaction = () => {
  const { isDesktopLarge } = useUserDevice();
  const navigate = useNavigate();
  const { paymentPlan, setPaymentPlan, updateTransactions } =
    usePaymentPlanContext();

  useEffect(() => {
    if (paymentPlan?.transactionToEdit?.state === 'DELETE') {
      updateTransactions();
      navigate('../confirm');
    }
  }, [paymentPlan?.transactionToEdit?.state]);

  const onDelete = () => {
    if (paymentPlan?.transactionToEdit) {
      setPaymentPlan({
        transactionToEdit: {
          amount: paymentPlan?.transactionToEdit?.amount,
          date: paymentPlan?.transactionToEdit?.date,
          state: 'DELETE',
        },
      });
    }
  };

  return (
    <PageWrapper
      isSingleColumn
      trackingProperties={{
        featureName: SETTLEMENTS_FEATURE_NAME,
        pageName: 'Edit Payment - Delete Payment Page',
      }}
    >
      <div className="flex flex-column mt5">
        <div className="flex flex-column">
          <ResponsiveTypography
            type="HEADER"
            desktopLevel="H3"
            mobileLevel="H2"
          >
            Are you sure you want to delete this payment?
          </ResponsiveTypography>
          <div className="mt4">
            <Notification variant="inline" level="warning">
              <span>
                If you delete this payment, you may have to adjust other
                payments to meet your settlement balance.
              </span>
            </Notification>
          </div>
        </div>
        <div className={cx('flex mt4', !isDesktopLarge && 'flex-column')}>
          <div className={cx(isDesktopLarge && 'mr2')}>
            <Button onPress={onDelete} text="Delete This Payment" />
          </div>
          <Button
            onPress={() => {
              // return to upcoming or scheduled payments list
              // depending where the user came from
              navigate(-2);
            }}
            variant="text"
            text="Cancel"
          />
        </div>
      </div>
    </PageWrapper>
  );
};

export default DeleteOfferTransaction;
