import { B, P1, P2, P4, Spacing } from '@missionlane/compass-ui';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useFlags } from 'launchdarkly-react-client-sdk';
import CreditScore from '../CreditScore/CreditScore';
import ProgressBar from '../CreditScore/ProgressBar/ProgressBar';
import LoadingSpinner from '../General/LoadingSpinner';
import ResponsiveTypography from '../General/ResponsiveTypography';
import { useAccount } from '../Auth/AccountContext';
import { useNavContainerContext } from '../Navigation/NavContainer';
import MyProgressOnboardingStepTile, {
  TileSVG,
} from './MyProgressOnboardingStepTile';
import DownloadMobileAppQRKard from './DownloadMobileAppQRKard';
import MyProgressOnboardingSuccessOverlay from './MyProgressOnboardingSuccessOverlay';
import { FEATURE_NAME } from './const';
import { useLocalStorage } from '@core/utils/hooks/useLocalStorage';
import {
  GetOnboardingProgressDetailsQuery,
  GetOnboardingProgressDetailsQueryVariables,
} from '@core/graphql/globalTypes';
import { getOnboardingProgressDetailsQuery } from '@core/queries/getOnboardingProgressDetailsQuery';
import { usePaymentStatus } from '@payments/hooks/usePaymentStatus';
import { withPageWrapper } from '@core/utils/hoc/withPageWrapper';
import { useTracking } from '@core/services/TrackService/useTracking';
import { MLFlags } from 'flags';

const autopayStorageKey = 'dismissedAutopayHotTipTileAt';
export const successStorageKey = 'dismissedCompletedOnboardingOverlayAt';

const MyProgressOnboardingPage = () => {
  const {
    myProgressNewFeatureNotificationViewed,
    updateMyProgressNewFeatureNotificationViewed,
  } = useNavContainerContext();
  const { trackClick, trackEvent } = useTracking();
  const { myProgressNewFeatureNotification } = useFlags<MLFlags>();

  const { accountId } = useAccount();
  const [completedSteps, setCompletedSteps] = useState(0);
  const { set, get } = useLocalStorage();
  const successOverlayDismissedAt = get(successStorageKey);
  const [dismissedSuccessOverlay, setDismissedSuccessOverlay] = useState(
    Boolean(successOverlayDismissedAt),
  );
  const autopayDismissedAt = get(autopayStorageKey);
  const [dismissedAutopay, setDismissedAutopay] = useState(
    Boolean(autopayDismissedAt),
  );
  const location = useLocation();
  const navigate = useNavigate();
  const {
    loading: paymentStatusLoading,
    canEnrollInAutopay,
    hasAutopay,
  } = usePaymentStatus();

  const { data, loading, error } = useQuery<
    GetOnboardingProgressDetailsQuery,
    GetOnboardingProgressDetailsQueryVariables
  >(getOnboardingProgressDetailsQuery, {
    variables: {
      accountId,
    },
    skip: !accountId,
    fetchPolicy: 'cache-and-network',
  });

  const { onboardingProgress, account } = data || {};

  const {
    hasMadePayment,
    hasUsedCard,
    isCardActivated,
    isFundingAccountAdded,
  } = onboardingProgress || {};

  const handleSuccessSheetOnDismiss = () => {
    set(successStorageKey, dayjs().toISOString());
    setDismissedSuccessOverlay(true);
    trackClick({
      name: `${FEATURE_NAME.MY_PROGRESS_ONBOARDING}: Success Overlay Dismissed`,
      feature: FEATURE_NAME.MY_PROGRESS_ONBOARDING,
    });
  };

  const handleActivateOnPress = () => {
    navigate('../activate');
  };

  const handleAddFundingAccountOnPress = () => {
    navigate(`../add-bank-account`, {
      state: {
        referrer: location.pathname,
      },
    });
  };

  const navigateToAutopay = () => navigate('../payments/autopay');

  useEffect(() => {
    let steps = 0;

    if (isCardActivated) {
      steps += 1;
    }
    if (isFundingAccountAdded) {
      steps += 1;
    }
    if (hasUsedCard) {
      steps += 1;
    }
    if (hasMadePayment) {
      steps += 1;
    }

    setCompletedSteps(steps);
  }, [isCardActivated, isFundingAccountAdded, hasMadePayment, hasUsedCard]);

  useEffect(() => {
    if (
      myProgressNewFeatureNotification.newestFeature !== null &&
      myProgressNewFeatureNotificationViewed !==
        myProgressNewFeatureNotification.newestFeature
    ) {
      updateMyProgressNewFeatureNotificationViewed(
        myProgressNewFeatureNotification.newestFeature,
      );
      trackEvent({ eventName: 'My Progress Tab Notification Dismissed' });
    }
  }, [
    myProgressNewFeatureNotification.newestFeature,
    myProgressNewFeatureNotificationViewed,
    trackEvent,
    updateMyProgressNewFeatureNotificationViewed,
  ]);

  if (loading || paymentStatusLoading) {
    // not using page loading spinner because this component gets wrapped in PageWrapper
    return <LoadingSpinner />;
  }

  if (error) throw error;

  // we use 0.02 instead of 0 so the progress bar shows a bit of green instead of fully empty
  const progress = completedSteps === 0 ? 0.02 : completedSteps / 4;

  const isAllStepsCompleted =
    isCardActivated && isFundingAccountAdded && hasUsedCard && hasMadePayment;

  return (
    <div
      data-testid="MyProgressOnboarding"
      className="flex w-100 h-100 flex-row-l relative flex-column justify-center"
    >
      {isAllStepsCompleted && !dismissedSuccessOverlay && (
        <MyProgressOnboardingSuccessOverlay
          onDismiss={handleSuccessSheetOnDismiss}
        />
      )}
      <div className="w-50-l">
        <ResponsiveTypography type="HEADER" mobileLevel="H2" desktopLevel="H3">
          Welcome to Mission Lane!
        </ResponsiveTypography>
        <P1 color="inkLight">Let’s get started:</P1>
        <div className="mv3">
          <ProgressBar
            color="green"
            backgroundColor="greyLightest"
            progress={progress}
            heightRem={0.33}
          />
          <P4 color="green">
            <B>{`${completedSteps}/4 Complete`}</B>
          </P4>
        </div>
        <MyProgressOnboardingStepTile
          buttonTestId="Activate-Button"
          testId="Activate card"
          title="Activate card"
          stepNumber="1"
          body="When your card arrives, activate it to start using your card."
          onClick={handleActivateOnPress}
          buttonLabel="Activate"
          isCompleted={Boolean(isCardActivated)}
          disabled={!account?.isEligibleForActivation}
        />
        <MyProgressOnboardingStepTile
          testId="Add bank account"
          title="Add bank account"
          stepNumber="2"
          body="You can do this before or after you activate your card."
          onClick={handleAddFundingAccountOnPress}
          buttonLabel="Add"
          isCompleted={Boolean(isFundingAccountAdded)}
        />
        <MyProgressOnboardingStepTile
          testId="Use Card"
          title="Use Card"
          stepNumber="3"
          body="Make your first purchase."
          isCompleted={Boolean(hasUsedCard)}
          tileSVG={TileSVG.cardInHand}
        />
        <MyProgressOnboardingStepTile
          testId="Pay"
          title="Pay"
          stepNumber="4"
          body="Make your first payment."
          isCompleted={Boolean(hasMadePayment)}
          tileSVG={TileSVG.calendar}
        />
        {!dismissedAutopay && canEnrollInAutopay && !hasAutopay && (
          <>
            <div style={{ marginTop: Spacing.m, marginBottom: Spacing.m }}>
              <P2 color="ink">
                <B>Hot Tip:</B>
              </P2>
            </div>
            <MyProgressOnboardingStepTile
              testId="Set up autopay"
              title="Set up autopay"
              body="Your best shot to avoid missing a payment"
              onClick={navigateToAutopay}
              buttonLabel="Set up"
              hasLargeTitle={false}
              onDismiss={() => {
                set(autopayStorageKey, dayjs().toISOString());
                setDismissedAutopay(true);
              }}
            />
          </>
        )}
      </div>
      <div className="mv3 w-50-l ma4-l">
        <DownloadMobileAppQRKard />
        <CreditScore isShadowHidden className="flex h-30" classNameBody="na3" />
      </div>
    </div>
  );
};

const MyProgressOnboardingPageWithPageWrapper = withPageWrapper(
  MyProgressOnboardingPage,
  {
    trackingProperties: {
      featureName: FEATURE_NAME.MY_PROGRESS_ONBOARDING,
      pageName: `${FEATURE_NAME.MY_PROGRESS_ONBOARDING}: Onboarding Progress`,
    },
  },
);

export default MyProgressOnboardingPageWithPageWrapper;
