import AuthenticatedBanners from './AuthenticatedBanners/AuthenticatedBanners';
import CustomerIOInlineMessage from './CustomerIOInlineMessage';
import ErrorBanner from './ErrorBanner/ErrorBanner';
import MaintenanceBanner from './MaintenanceBanner/MaintenanceBanner';

interface BannerContainerProps {
  banners?: React.ComponentType[];
  /** This props is used by the loading of the login flow because some
   * of the components that render the loading spinner during the login flow end up being part of the authed experience.
   * That can cause authed banners to display
   */
  hideAuthenticatedBanners?: boolean;
}

const BannerContainer = ({
  banners = [],
  hideAuthenticatedBanners = false,
}: BannerContainerProps) => {
  return (
    <>
      <MaintenanceBanner />
      {!hideAuthenticatedBanners && (
        <>
          <AuthenticatedBanners />
          {banners.map((BannerComponent, index) => (
            <BannerComponent key={index} />
          ))}
        </>
      )}

      <ErrorBanner />
      <CustomerIOInlineMessage />
    </>
  );
};

export default BannerContainer;
