import { B } from '@missionlane/compass-ui';
import Accordian from '../General/Accordian/Accordian';
import { AccordianItemProps } from '../General/Accordian/AccordianItem';
import Modal from '../General/Modal/Modal';
import ResponsiveTypography from '../General/ResponsiveTypography';
import CreditProtectionFAQ_1, {
  CREDIT_PROTECTION_FAQ_1_TITLE,
} from './CreditProtectionQuestions/CreditProtectionFAQ_1';
import CreditProtectionFAQ_2, {
  CREDIT_PROTECTION_FAQ_2_TITLE,
} from './CreditProtectionQuestions/CreditProtectionFAQ_2';
import CreditProtectionFAQ_3, {
  CREDIT_PROTECTION_FAQ_3_TITLE,
} from './CreditProtectionQuestions/CreditProtectionFAQ_3';
import CreditProtectionFAQ_4, {
  CREDIT_PROTECTION_FAQ_4_TITLE,
} from './CreditProtectionQuestions/CreditProtectionFAQ_4';
import CreditProtectionFAQ_5, {
  CREDIT_PROTECTION_FAQ_5_TITLE,
} from './CreditProtectionQuestions/CreditProtectionFAQ_5';

interface CreditProtectionFAQModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const CreditProtectionFAQModal = ({
  isOpen,
  onClose,
}: CreditProtectionFAQModalProps) => {
  const faqItems: AccordianItemProps[] = [
    {
      title: CREDIT_PROTECTION_FAQ_1_TITLE,
      children: <CreditProtectionFAQ_1 />,
    },
    {
      title: CREDIT_PROTECTION_FAQ_2_TITLE,
      children: <CreditProtectionFAQ_2 />,
    },
    {
      title: CREDIT_PROTECTION_FAQ_3_TITLE,
      children: <CreditProtectionFAQ_3 />,
    },
    {
      title: CREDIT_PROTECTION_FAQ_4_TITLE,
      children: <CreditProtectionFAQ_4 />,
    },
    {
      title: CREDIT_PROTECTION_FAQ_5_TITLE,
      children: <CreditProtectionFAQ_5 />,
    },
  ];

  return (
    <Modal
      headerText="Mission Lane Credit Protection"
      isOpen={isOpen}
      onClose={onClose}
    >
      <ResponsiveTypography type="PARAGRAPH" mobileLevel="P1" desktopLevel="P3">
        This optional service can provide help with your credit card payments if
        you experience certain financial hardships that could impact your
        ability to pay.
        <br />
        <br />
        <B color="ink">
          See the Mission Lane Credit Protection Terms and Conditions for
          limitations and complete details.
        </B>
      </ResponsiveTypography>
      <div className="mv4">
        <Accordian items={faqItems} />
      </div>
      <ResponsiveTypography type="PARAGRAPH" mobileLevel="P1" desktopLevel="P3">
        The following limitations apply to the Mission Lane Credit Protection
        program.   The minimum payment benefit is the minimum payment amount on
        the account statement that corresponds to the date the benefit was
        approved.  The new balance benefit is the new balance on the account
        statement that corresponds to the date the eligible event occurred,
        minus any minimum payments already made for the eligible event.  
        Hospitalization benefits, auto service benefits, medical expense
        benefits, and natural disaster benefits are each limited to one per
        calendar year.  See the Mission Lane Credit Protection Terms and
        Conditions for complete details.
      </ResponsiveTypography>
    </Modal>
  );
};

export default CreditProtectionFAQModal;
