import { useNavigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  CLOSE_BUTTON_WIDTH,
  OpportunityTileContainer,
  OpportunityTileCTA,
} from '../OpportunityTileCarousel/OpportunityTiles/LayoutComponents';
import { CarouselTileProps } from '../Carousel/Carousel';
import { REFERRALS_QUERY } from './ReferralsQuery';
import singleHand from '@core/assets/singleHand.svg';
import greenTracks from '@core/assets/greenTracks.svg';
import { MLFlags } from 'flags';
import {
  ReferralOffersQuery,
  ReferralOffersQueryVariables,
} from '@core/graphql/globalTypes';
import { useAccountIdQuery } from '@core/utils/hooks/useAccountIdQuery';
import { TrackServiceEventProperties } from '@core/services/TrackService/TrackService';
import './Referrals.css';

export const REFERRALS_TILE_EVENT = 'Opportunity Tile: Referrals';

export const getCopy = (
  flagVariant: string,
  statementCreditPerReferral: number,
  maximumStatementCredit: number,
) => {
  if (flagVariant === 'INCENTIVE_A') {
    return {
      title: 'Refer A Friend',
      body: `Earn $${statementCreditPerReferral} for each friend who gets approved for a Mission Lane card!`,
    };
  }
  if (flagVariant === 'INCENTIVE_B') {
    return {
      title: 'Refer Your Friends',
      body: `Earn up to $${maximumStatementCredit} when your friends are approved for Mission Lane!`,
    };
  }
  if (flagVariant === 'NON_INCENTIVE_A') {
    return {
      title: 'Share the Lane',
      body: 'Refer your friends and family to the Mission Lane Visa® credit card.',
    };
  }
  if (flagVariant === 'NON_INCENTIVE_B') {
    return {
      title: 'Spread the Love',
      body: 'Refer your friends and family to the Mission Lane Visa® credit card.',
    };
  }

  return { title: '', body: '' };
};

const IMAGE_WIDTH = 80;
const IMAGE_RIGHT = 6;
const CONTENT_SAFE_MARGIN = IMAGE_WIDTH - CLOSE_BUTTON_WIDTH - IMAGE_RIGHT;

const ReferralsTile = ({ isVisible }: CarouselTileProps) => {
  const { referralsMarketing } = useFlags<MLFlags>();
  const navigate = useNavigate();

  // TODO: EEC-183 - Remove this once we get the copy from Apollo
  const { data, loading, error } = useAccountIdQuery<
    ReferralOffersQuery,
    ReferralOffersQueryVariables
  >(REFERRALS_QUERY);

  if (loading || error !== undefined) {
    return null;
  }

  const statementCreditPerReferral =
    (data?.referralOffers &&
      data.referralOffers[0].data.statementCreditPerReferral) ||
    0;

  const maximumStatementCredit =
    (data?.referralOffers &&
      data.referralOffers[0].data.maximumStatementCredit) ||
    0;

  const offerVariant =
    (data?.referralOffers && data.referralOffers[0].data.offerVariant) ||
    'UNKNOWN';

  // TODO: EEC-183 - Remove this once we get the copy from Apollo
  const { title, body } = getCopy(
    referralsMarketing,
    statementCreditPerReferral / 100,
    maximumStatementCredit / 100,
  );

  const trackingVariant: TrackServiceEventProperties = {
    metadata: {
      offerVariant,
    },
  };

  return (
    <OpportunityTileContainer
      trackingProperties={{
        feature: REFERRALS_TILE_EVENT,
        ...trackingVariant,
      }}
      backgroundColor="pine"
      isVisible={isVisible}
    >
      <OpportunityTileCTA
        trackingName={REFERRALS_TILE_EVENT}
        trackingProperties={trackingVariant}
        contentSafeMargin={CONTENT_SAFE_MARGIN}
        title={title}
        text={body}
        onButtonClick={() => navigate('../referrals')}
        buttonLabel="Start Referring"
        theme="dark"
      />
      <img src={singleHand} className="referral-tile-hand" />
      <img src={greenTracks} className="referral-tile-tracks" />
    </OpportunityTileContainer>
  );
};

export default ReferralsTile;
