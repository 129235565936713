import { gql } from '@apollo/client';
import { Button, Icon } from '@missionlane/compass-ui';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { ScheduledPaymentListItemProps } from './ScheduledPaymentListItem';
import ScheduledPaymentsList from './ScheduledPaymentsList';
import PageWrapper from '@core/components/Page/PageWrapper';
import {
  ActivityState,
  ScheduledPaymentsQuery,
  ScheduledPaymentsQueryVariables,
} from '@core/graphql/globalTypes';
import AUTOPAY_FIELDS from '@payments/graphql/AutopayFieldsFragment';
import PAYMENT_FIELDS from '@payments/graphql/PaymentFieldsFragment';
import { useAccountIdQuery } from '@core/utils/hooks/useAccountIdQuery';
import { getAmountDisplay } from '@payments/components/Autopay/utils/helpers';
import TrackingLink from '@core/components/General/TrackingLink';
import { getOrdinalFromDayOfMonth } from '@core/utils/getOrdinalDate';
import { useCustomerAndAccountIdentifiers } from '@core/utils/hooks/useCustomerAndAccountIdentifiers';
import { centsToDollars } from '@core/utils/centsToDollars';
import { canCancelPayment } from '@payments/utils/canCancelPayment';
import LoadingSpinner from '@core/components/General/LoadingSpinner';

export const SCHEDULED_PAYMENTS_QUERY = gql`
  query ScheduledPayments($accountId: String!) {
    account(accountId: $accountId) {
      id
      upcomingPayments {
        ...PaymentFields
      }
      autopay {
        ...AutopayFields
      }
    }
  }
  ${PAYMENT_FIELDS}
  ${AUTOPAY_FIELDS}
`;

const ScheduledPayments = () => {
  const navigate = useNavigate();
  const { data, loading } = useAccountIdQuery<
    ScheduledPaymentsQuery,
    ScheduledPaymentsQueryVariables
  >(SCHEDULED_PAYMENTS_QUERY);

  const { autopay = [], upcomingPayments = [] } = data?.account || {};
  const customerAndAccountIds = useCustomerAndAccountIdentifiers();

  const mappedUpcomingPayments = useMemo(
    () =>
      upcomingPayments.reduce<
        (ScheduledPaymentListItemProps & { sortDate: Date })[]
      >((payments, payment) => {
        if (payment.state === ActivityState.Scheduled) {
          return [
            ...payments,
            {
              iconName: 'calendar',
              label: 'One-Time',
              secondaryLabel: centsToDollars(payment.amount),
              description: dayjs(payment.date).format('MMM DD, YYYY'),
              sortDate: new Date(payment.date),
              linkComponent:
                canCancelPayment(payment) ?
                  <TrackingLink
                    to={`./cancel-payment/${payment.id}`}
                    trackingName="Cancel Scheduled Payment"
                    trackingProperties={customerAndAccountIds}
                  >
                    Cancel
                  </TrackingLink>
                : null,
            },
          ];
        }
        return payments;
      }, []),
    [upcomingPayments],
  );

  const mappedAutopay: (ScheduledPaymentListItemProps & { sortDate: Date })[] =
    useMemo(
      () =>
        autopay.map((autopayItem) => {
          return {
            iconName: 'autopay',
            label: 'Autopay',
            secondaryLabel: getAmountDisplay(
              autopayItem.paymentType,
              autopayItem.amount,
            ),
            description: `${dayjs(autopayItem.nextPaymentDate).format(
              'MMM DD, YYYY',
            )} (every ${getOrdinalFromDayOfMonth(autopayItem.dayOfMonth)})`,
            sortDate:
              autopayItem.nextPaymentDate ?
                new Date(autopayItem.nextPaymentDate)
              : new Date(),
            linkComponent: (
              <TrackingLink
                to="../autopay/manage"
                trackingName="Edit Autopay from Scheduled Payments"
                trackingProperties={customerAndAccountIds}
              >
                <Icon name="edit" color="blue" />
              </TrackingLink>
            ),
          };
        }),
      [autopay],
    );

  const scheduledPaymentsData: ScheduledPaymentListItemProps[] = useMemo(() => {
    // Combine and sort by the sortDate property
    const allPayments = [...mappedUpcomingPayments, ...mappedAutopay];
    return allPayments
      .sort((a, b) => a.sortDate.getTime() - b.sortDate.getTime())
      .map(({ sortDate, ...rest }) => rest); // Remove the sortDate property from the final result
  }, [mappedUpcomingPayments, mappedAutopay]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <PageWrapper
      isSingleColumn
      pageTitle={{ primaryText: 'Scheduled Payments', useLast4: true }}
      trackingProperties={{
        featureName: 'Payments',
        pageName: 'Scheduled Payments ',
      }}
    >
      <ScheduledPaymentsList scheduledPayments={scheduledPaymentsData} />
      <div className="flex mt4">
        <Button
          text="Back"
          onPress={() => {
            navigate('../../summary');
          }}
        />
      </div>
    </PageWrapper>
  );
};

export default ScheduledPayments;
